import React, {  useEffect,Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import { Link, useNavigate,useMatch, useParams } from "react-router-dom";
import StepOneCredit from "./StepOneCredit";
import StepTwoCreditPreview from "./StepTwoCredit";
import PropTypes from "prop-types";
import api from "../../../../utils/api";
// import {
// 	transferCredit
//   } from "../../../../store/actions/profileAction";
// import StepThree from "./StepThree";
// import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";
import { usePaystackPayment, PaystackButton, PaystackConsumer } from '../../../../dist/index.es';
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";

import {
	transferCredit,

  } from "../../../../store/actions/profileAction";

const initialState ={


	receiver_username: '',
	credit: '',
	password: ''

}
const RegisterPhone = ({
	profile: { profile, balance, loading },
	transferCredit,
	phone,
	name,
	email
  
  }) => {
	const [goSteps, setGoSteps] = useState(0);

	const [ref, setRef] = useState('');
	const [user, setUser] = useState({});
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

   
	
	const {
	
		receiver_username,
		credit,
		password,
		unit,
		purpose


		} = formData;

  
	let errorsObj = {		
	
			receiver_username:'',
			credit:'',
			password:'',
			unit: 0,
			purpose: ''

	 	};

	useEffect(() => {
	
	calculatedAmount()

	}, [unit, purpose ]);


	const [errors, setErrors] = useState(errorsObj);

	const onChange = (e) =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });
	
	const calculatedAmount = ()=>{

		let calAmount = 0
		let price = 0

		if( purpose === 'validate' && unit > 0 ) {
			price = 1950
	
		}

		if( purpose === 'declare' && unit > 0 ) {
			price = 3500
			
		}
		 calAmount = unit * price
	
		 
		setFormData({ ...formData, credit: calAmount});


	}  



	   const  checkUser = async ()=> {

		await api.get(`/users/check-user/${receiver_username}`).then((res) => {
			//console.log(res.data)
			if(res.data.isError){
					if(res.data.msg.errors[0].msg ===`Invalid User details.` ){
						swal('Oops',"This username does not exist", {
						icon: "error",
						});
						
					}else{
						swal('Oops', `${res.data.msg.errors[0].msg} ` , {
							icon: "error",
							});

					}
				}else  setUser(res.data)
			}) .catch((error) => {
				console.error(error)
			 });
	   }

	   const onCheckUser = async(e)=>{
			checkUser()
			setGoSteps(1)
	   }

   const  onSubmit = async (e)=> {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
	

		if (parseInt(user.phone) === parseInt(profile.phoneno) ) {
          //  errorObj.receiver_username = 'Transfer to Self is not allowed';
            error = true;
			swal('Oops', 'Check the username, Transfer to one Self is not allowed', "error");
        }

     	if (receiver_username === '') {
            errorObj.receiver_username = 'User name is Required';
            error = true;
			swal('Oops', errorObj.receiver_username, "error");
        }

		 if (credit === '') {
            errorObj.credit = 'Credit is Required';
            error = true;
			swal('Oops', errorObj.credit, "error");
        }
		
		if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			swal('Oops', errorObj.password, "error");
        }

		if (purpose === '') {
            errorObj.purpose = 'Purpose is Required';
            error = true;
			swal('Oops', errorObj.purpose, "error");
        }
		
		if (unit === '') {
            errorObj.unit = 'Unit is Required';
            error = true;
			swal('Oops', errorObj.unit, "error");
        }

        setErrors(errorObj);

        if (error) return;

		transferCredit(formData, navigate, false).then((res) => 
		
			{
				if(!res.isError){
						swal(`Transfer Successful!`, {
					icon: "success",
					});
					//setAddCard(false);
					navigate("/my-phones")
				}else{
					swal(`${res.msg}`);
				}
				
			}
		
		);


    }
	   
	return (
		<Fragment>
			{/* <PageTitle activeMenu="Add Phone Service" motherMenu="Home" /> */}

			
			<div className='row'>
					<div className='col-lg-4 mb-2'>
					<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button> 
					</div>
				
					
				
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							 <h4 className="card-title">Transfer Credit</h4>
							{/*<h6>New Phone Registration Fee</h6>  */}
							
						</div>
						<form onSubmit={(e) => onSubmit(e)}>
								<div className="card-body">
								<div className="text-center mb-3">
									
								{/* <h4 className="card-title">Phone Registration Service</h4> */}
								<h6>My Phone Shop Validation Credit Balance</h6>
								
								<Fragment>
									<div>&#8358;{balance}</div> 
								</Fragment>
								
								
								
                   		 </div>
							<div className="form-wizard ">

								{/* <Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper> */}
								
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									{/* <Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
								</Stepper>

							  {goSteps === 0 && (
								<>
								   <StepOneCredit 
									formData={formData} 
									onChange={onChange}
									 />	
									
									<div className="text-end toolbar toolbar-bottom p-2"> 
										<button  className="btn btn-warning sw-btn-next" onClick={(e) => onCheckUser() } >Next</button>
									</div>	
								</>
							  )}
{/* setGoSteps(1) */}
{/* onClick={(e) => checkReferral(e)} */}
							  {/* {goSteps === 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )} */}
							  {goSteps === 1 && (
								<>
									<StepTwoCreditPreview formData={formData} 	user={user} />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-warning sw-btn-prev me-1" onClick={() => {setGoSteps(0); setUser('')}}>Prev</button>
										

										
        									  <Fragment>						
												<button   className="btn btn-secondary sw-btn-next ms-1" >submit</button>
											</Fragment>
           									
									</div>	
								</>	
							  )}
							  
							</div>
						</div>
						</form>
				

					</div>
				</div>
			</div>
		</Fragment>
	);
};


RegisterPhone.propTypes = {

	transferCredit: PropTypes.func.isRequired,
	
	profile: PropTypes.object.isRequired,
	
	
  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
	phone: state.auth.auth.phone,


  });
  const mapDispatchToProps = { transferCredit};
  export default connect(mapStateToProps, mapDispatchToProps)(RegisterPhone);