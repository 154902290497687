import React, {  useEffect,Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import { Link, useNavigate,useMatch, useParams } from "react-router-dom";
import StepOneCredit from "./StepOneCredit";
import StepTwoCreditPreview from "./StepTwoCredit";
import PropTypes from "prop-types";
import api from "../../../../utils/api";
// import {
// 	AddFund
//   } from "../../../../store/actions/profileAction";
// import StepThree from "./StepThree";
// import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";
import { usePaystackPayment, PaystackButton, PaystackConsumer } from '../../../../dist/index.es';
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";

import {
	AddFund,
	getCurrentProfile,
  } from "../../../../store/actions/profileAction";
 // import { profileStatus } from '../../../../store/selectors/AuthSelectors';
const initialState ={


	receiver_username: '',
	credit: '',
	password: '',
	unit: '',
	purpose: ''

}
const RegisterPhone = ({
	profile: { profile, balance, loading },
	getCurrentProfile,
	AddFund,
	auth: {phone},
	name,
	email,
	asg
  
  }) => {
	const [goSteps, setGoSteps] = useState(0);

	const [ref, setRef] = useState('');
	const [user, setUser] = useState({});
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
	const [payFlag,setPayFlag] = useState(true)
   
	
	const {
	
		receiver_username,
		credit,
		password,
		unit,
		purpose

		} = formData;

  
	let errorsObj = {		
	
			receiver_username:'',
			credit:'',
			password:'',
			unit: '',
			purpose: ''
	

	 	};

		 useEffect(() => {
			const userData = { ...initialState };
			// if there is no profile, attempt to fetch one
			!profile && getCurrentProfile();
			//id  && getPhoneStatus()
			// if(profile && !loading ) {
				
			// 	const user = profile?.phone_detail.find((element) => {
			// 		return element._id === formData.phoneid;
			// 		})
			// 		//console.log(user);
				 
			// 	  for (const key in user) {
			// 		//console.log(key);
			// 		if (key in userData) userData[key] = user[key];
			// 	  }
			  // // set local state with the userData
			//   		 setFormData(userData);
			// 		//   
				
			// }
		  }, [loading, profile, getCurrentProfile]);


			// delete data  
			// const handleDelete = () => {
			// 	let msg = 'Note that deliberate declaring of your phone missing causes authorities to track down to you and there is penalty for this.'
			// 	let msg2 = 'Once declared, the details of the declared phone changes to IN USE'
			// 	let message = ''
			// 	//displayInUse?message=msg2:message=msg 
			// 	swal({
			// 		title: "Are you sure?",
			// 		text:
			// 		  `${message}`,
			// 		icon: "warning",
			// 		buttons: true,
			// 		dangerMode: true,
			// 	  }).then((willDelete) => {
			// 		if (willDelete) {
			// 			if(phoneStatus === ''){
			// 				swal(`Enter Phone status!`)
			// 				return;
			// 			  }
			// 			  let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString(),  trans_pay_method: 'paystack', trans_amount: 3500, trans_name: 'Declare phone missing ' }
			// 			AddFund(formData2, navigate).then((res) => {
			// 			if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
			// 				swal(`${res}`);
			// 			}else {
			// 					swal(`Phone status has change to ${formData.phoneStatus}!`, {
			// 				icon: "success",
			// 				});
			// 				//setAddCard(false);
			// 				navigate("/my-phones")
							
			// 			}
						
			// 		});
			// 		} else {
			// 		  swal(`Phone is not declared ${message}!`);
			// 		}
			// 	  })
		
			// }

			const onClose = () => {
				// implementation for  whatever you want to do when the Paystack dialog closed.
				console.log('closed')
			}

			const onSuccess = (reference) => {
			
				//setRef(reference)
		
				let formData2 = {...formData, trans_ref: reference,  trans_pay_method: 'paystack', trans_amount: 3500, trans_name: 'Fund User Account - online' }
		
				AddFund(formData2, navigate).then((res) => {
					if(res !=='Invalid Credentials'){
							swal(`User account funded!`, {
						icon: "success",
						});
						//setAddCard(false);
						navigate("/my-phones")
					}else{
						swal("Invalid Credentials!");
					}
					
				});
				
				
			};

			const componentProps = {
				//   ...config,
				reference: (new Date()).getTime().toString(),
				email:email,
				amount:3500*100,
				metadata: {
					name,
					 phone,
				  },
				publicKey: publicKey,
				
			   //  lastname: 'story',
				   text: 'Proceed to CHECKOUT ',
				   onSuccess,
				   onClose
			};

			const handlePayFlagClick = () => {
				setPayFlag(!payFlag);    
				
		   };



		   
		   const payButton = ()=>	{
	  
			let onlinePay = ''
			if(!payFlag){
				   onlinePay = <PaystackButton className="btn btn-secondary my-1" {...componentProps} />
			}else{
				  onlinePay = <button type="submit" className="btn btn-secondary my-1" onClick={onSubmit}>ADD Direct Credit</button>   
			}
		  
			  return (<Fragment>
						  
							  <div className="col-xl-12 col-lg-8 ml-14">
								  <div className="card">
									  <div className="card-header ">
									  <h4 className="card-title">Payment Options</h4>
									  </div>
									  <div className="card-body">
									  <div className="basic-form">
										  {/* <form onSubmit={(e) => e.preventDefault()}> */}
										  <div className="form-group mb-0 " >
												  {	
										  
												  payFlag   &&  
												  (<Fragment>
													  
													  <div className="radio">
														  <label>
															  <input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Add Direct Credit</b>
														  </label>
													  </div>
													  <div className="radio">
														  <label>
															  <input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Pay Online</b>
														  </label>
													  </div>
												  </Fragment>
												  )
		  
											  }
											  {	
										  
												  !payFlag   && 
												  (<Fragment>
													  <div className="radio">
														  <label>
															  <input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Add Direct Credit</b>
														  </label>
													  </div>
													  <div className="radio">
														  <label>
															  <input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Pay Online</b>
														  </label>
													  </div>
												  </Fragment>
												  )
		  
											  }

										  </div>
										  {/* </form> */}
									  </div>
									  </div>
								  </div>
							  </div>
							  <div className="modal-footer">
								  {onlinePay}
								  <button type="button" onClick={()=> {  setPayFlag(true); setFormData({ ...formData, phoneStatus: ''});}  } className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
	  
							  </div>
		  
			  </Fragment>)
	
		  
			};

			const handleWallet = () => {

				swal({
					title: "Are you sure?",
					text:
					  `Once submitted, the user account will be credited`,
					icon: "warning",
					buttons: true,
					dangerMode: true,
				  }).then((willDelete) => {
					if (willDelete) {
						let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString(),  trans_pay_method: 'admin', trans_amount: 3500, trans_name: 'Fund User Account - Direct' }
		
						AddFund(formData2, navigate).then((res) => {
						if( res ==='Invalid Credentials' || res ==='Transaction Error'){
							swal(`${res}`);
						}else {
								swal(`User account funded!`, {
							icon: "success",
							});
							//setAddCard(false);
							navigate("/dashboard")
							
						}
						
					});
					} else {
					  swal("Phone status is not change");
					}
				  })
		
			}

	const [errors, setErrors] = useState(errorsObj);

	const onChange = (e) =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });
	

	useEffect(() => {
			
		calculatedAmount()

	  }, [unit, purpose ]);

	const calculatedAmount = ()=>{

		let calAmount = 0
		let price = 0
		let commision = 0
		let calAmountToPay = 0
		let credit = 1950
		let credit2 = 3500
		if( purpose === 'validate' && unit > 0 ) {
			price = profile?.profileStatus==='M-RIGHT REGISTRAR'? 1550:1950
			commision = 400 * unit
		}

		if( purpose === 'declare' && unit > 0 ) {
			price =  profile?.profileStatus==='M-RIGHT REGISTRAR'? 3100:3500
			commision = 400 * unit
		}
		 calAmount = unit *   (purpose === 'declare'? credit2:credit)
		 calAmountToPay = price * unit 
		 
		setFormData({ ...formData, credit: calAmount});
		//setAmountToPay(calAmountToPay);

	}  


	   const  checkUser = async ()=> {

		await api.get(`/users/check-user/${receiver_username}`).then((res) => {
			//console.log(res.data)
			if(res.data.isError){
					if(res.data.msg.errors[0].msg ===`Invalid User details.` ){
						swal('Oops',"This username does not exist", {
						icon: "error",
						});
						
					}else{
						swal('Oops', `${res.data.msg.errors[0].msg} ` , {
							icon: "error",
							});

					}
				}else  setUser(res.data)
			}) .catch((error) => {
				console.error(error)
			 });
	   }

	   const onCheckUser = async(e)=>{
			checkUser()
			setGoSteps(1)
	   }

	   const  onSubmit = async ()=> {

			// e.preventDefault();
				let error = false;
				const errorObj = { ...errorsObj };
			

				if (parseInt(receiver_username) === parseInt(profile.phoneno) ) {
				//  errorObj.receiver_username = 'Transfer to Self is not allowed';
					error = true;
					swal('Oops', `Check the username, Transfer to one Self is not allowed - ${receiver_username}`, "error");
				}
				//return;
				if (receiver_username == '') {
					errorObj.receiver_username = 'User name is Required';
					error = true;
					swal('Oops', errorObj.receiver_username, "error");
				}
		
				if (credit == '') {
					errorObj.credit = 'Credit is Required';
					error = true;
					swal('Oops', errorObj.credit, "error");
				}

				if (purpose == '') {
					errorObj.purpose = 'Purpose is Required';
					error = true;
					swal('Oops', errorObj.purpose, "error");
				}
				if (unit == '') {
					errorObj.unit = 'Unit is Required';
					error = true;
					swal('Oops', errorObj.unit, "error");
				}
				
				if (password == '') {
					errorObj.password = 'Password is Required';
					error = true;
					swal('Oops', errorObj.password, "error");
				}

				setErrors(errorObj);

				if (error) return;

				handleWallet(formData, navigate, false).then(() => navigate("/my-phones"));


			}
	   
	return (
		<Fragment>
			{/* <PageTitle activeMenu="Add Phone Service" motherMenu="Home" /> */}

			
			<div className='row'>
					<div className='col-lg-4 mb-2'>
					<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button> 
					</div>
				
					
				
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							 <h4 className="card-title">Add Credit to User Account</h4>
							{/*<h6>New Phone Registration Fee</h6>  */}
							
						</div>
						{/* <form onSubmit={(e) => onSubmit(e)}> */}
								<div className="card-body">
								<div className="text-center mb-3">
									
								{/* <h4 className="card-title">Phone Registration Service</h4> */}
								{/* <h6>My Credit Balance</h6>
								
								<Fragment>
									<div>&#8358;{balance}</div> 
								</Fragment> */}
								
								
								
                   		 </div>
							<div className="form-wizard ">

								{/* <Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper> */}
								
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									{/* <Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
								</Stepper>

							  {goSteps === 0 && (
								<>
								   <StepOneCredit 
									formData={formData} 
									onChange={onChange}
									 />	
									
									<div className="text-end toolbar toolbar-bottom p-2"> 
										<button  className="btn btn-warning sw-btn-next" onClick={(e) => onCheckUser() } >Next</button>
									</div>	
								</>
							  )}
{/* setGoSteps(1) */}
{/* onClick={(e) => checkReferral(e)} */}
							  {/* {goSteps === 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )} */}
							  {goSteps === 1 && (
								<>
									<StepTwoCreditPreview formData={formData} 	user={user} />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-warning sw-btn-prev me-1" onClick={() => {setGoSteps(0); setUser('')}}>Prev</button>
										
{payButton()}
										
        									  {/* <Fragment>						
												<button   className="btn btn-secondary sw-btn-next ms-1" >submit</button>
											</Fragment> */}
           									
									</div>	
								</>	
							  )}
							  
							</div>
						</div>
						{/* </form> */}
						

					</div>
				</div>
			</div>
		</Fragment>
	);
};


RegisterPhone.propTypes = {

	AddFund: PropTypes.func.isRequired,
	
	profile: PropTypes.object.isRequired,
	
	getCurrentProfile: PropTypes.func.isRequired,

  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
	auth: state.auth.auth,
   

  });

  const mapDispatchToProps = { AddFund,getCurrentProfile};

  export default connect(mapStateToProps, mapDispatchToProps)(RegisterPhone);