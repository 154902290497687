import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate}  from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {nanoid} from 'nanoid';
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';
//import Editable from './Editable';
import { usePaystackPayment, PaystackButton, PaystackConsumer } from '../../dist/index.es';
import EditService from './EditService';
import { connect } from 'react-redux';
import { TimeAgo, FormatDate, TimeDiff, IsExpired } from './TimeAgo'
import {
	deletePhoneAction_ChangeStatus,
	deletePhoneAction_ChangeStatusValidated,
	getCurrentProfile,
	
  } from "../../store/actions/profileAction";
  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';

 // import useAuth from '../../config/hooks/useAuth';


 const initialState ={
	
	phoneStatus: 'IN USE',
	phoneid: '',
	password: '',
	phoneName:'',
	prof_id:null,

}
const MyPhones = ({
	deletePhoneAction_ChangeStatus,
	deletePhoneAction_ChangeStatusValidated,
	profile: { profile, credits, balance, loading },
	getCurrentProfile,

	name,
	email,
	phone
  
  }) =>{
	
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const [validationFlag,setValidationFlag] = useState(false)
	const [payFlag,setPayFlag] = useState(true)
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
	const [ref, setRef] = useState('');
	const [payMethod, setPayMethod] = useState('');
	const [addCard, setAddCard] = useState(false);
	const [addCard2, setAddCard2] = useState(false);
	const [regDate, setRegDate] = useState('');
	let checked = ''
	//const [errors, setErrors] = useState(errorsObj);
//	const [list, //setList] = useState();
	//const { department,serviceDesc } = useAuth()
   // const [id, setId] = useState(department);
	

	// delete data  
    const handleDeleteClick = (contentId) => {
       // const newList = [...list];    
       // const index = list.findIndex((content)=> content.id === contentId);
        //newList.splice(index, 1);
        //setList(newList);

		//const delContent = {service_id: id,	_id: contentId};

        swal({
			title: "Are you sure?",
			text:
			  "Once deleted, you will not be able to recover this file!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			  swal("File has been deleted!", {
				icon: "success",
			  });
             //   deleteServiceAction(id,delContent);
			} else {
			  swal("File is not safe!");
			}
		  })


    }
	
	const onSuccess = (reference) => {

		let trans_amount = !addCard2?3500*100:profile?.appStatus? 1550*100:1950*100
		//let trans_amount = !addCard2?3500*100:profile?.profileStatus==='M-RIGHT REGISTRAR'? 1100*100:1500*100
		//profile.appStatus?profile.revoke?'Agentship Revoked':'Approved':'Pending'
		let trans_name = !addCard2?'Declare phone missing':'Phone Validation'
		let formData2 = {...formData, trans_ref: reference,  trans_pay_method: 'paystack', trans_amount: trans_amount, trans_name: trans_name }

		!addCard2 && deletePhoneAction_ChangeStatus(formData2, navigate).then((res) => {
			if(res !=='Invalid Credentials'){
					swal(`Phone status has changed to ${formData.phoneStatus}!`, {
				icon: "success",
				});
				setAddCard(false);
				navigate("/my-phones")
			}else{
				swal("Invalid Credentials!");
			}
			
		});

		addCard2 &&  deletePhoneAction_ChangeStatusValidated(formData2, navigate).then((res) => {
			if(res !=='Invalid Credentials'){
					swal(`Phone status has changed to Validated!`, {
				icon: "success",
				});
				setAddCard2(false);
				navigate("/my-phones")
			}else{
				swal("Invalid Credentials!");
			}
			
		});

		
		
	};
	// const onSuccess_validate = (reference) => {

	// 	let formData2 = {...formData, trans_ref: reference,  trans_pay_method: 'paystack', trans_amount: 1500, trans_name: 'Declare phone missing ' }

	// 	deletePhoneAction_ChangeStatusValidated(formData2, navigate).then((res) => {
	// 		if(res !=='Invalid Credentials'){
	// 				swal(`Phone status has change to ${formData.phoneStatus}!`, {
	// 			icon: "success",
	// 			});
	// 			setAddCard2(false);
	// 			navigate("/my-phones")
	// 		}else{
	// 			swal("Invalid Credentials!");
	// 		}
			
	// 	});
		
		
	// };
	
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed')
	}

	//Modal box
	
	//Add data 
    const [addFormData, setAddFormData ] = useState({
        name:'',
        serviceRate:'',
        serviceDesc:'',

    }); 

    	useEffect(() => {
			 getCurrentProfile();
	   }, [getCurrentProfile]);

	   const {	password, phoneid,phoneStatus, 	phoneName} = formData;
	 
	   let errorsObj = {
		   phoneid:'',
		   password:'',
		   phoneStatus:'',
		   phoneName:'',
		  };
		  const [errors, setErrors] = useState(errorsObj);

    // Add contact function
    const handleAddFormChange = (event) => {
        event.preventDefault();    
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

	const componentProps = {
		//   ...config,
		reference: (new Date()).getTime().toString(),
		email:email,
		amount:!addCard2?3500*100:profile?.appStatus? 1550*100:1950*100,
		//amount:!addCard2?3500*100:profile?.profileStatus==='M-RIGHT REGISTRAR'? 1100*100:1500*100,
		//let trans_amount = !addCard2?3500*100:profile?.appStatus? 1100*100:1500*100
		metadata: {
			name,
			 phone,
		  },
		publicKey: publicKey,
	
		
	   //  lastname: 'story',
		   text: 'Proceed to CHECKOUT',
		   onSuccess,
		   onClose
	   };



	  
    const handleDelete = () => {

        swal({
			title: "Are you sure?",
			text:
			  `Once submitted, phone status will change to ${formData.phoneStatus}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {

				
				let formData2 = {...formData, trans_ref: '', gateway_msg: '', trans_pay_method: 'wallet', trans_amount: 3500, trans_name: 'Declare phone missing ' }

				deletePhoneAction_ChangeStatus(formData2, navigate).then((res) => {
					if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
						swal(`${res}`);
					}else {
							swal(`Phone status has change to ${formData.phoneStatus}!`, {
						icon: "success"
						});
						setAddCard(false);
						navigate("/my-phones")
						
					}
				
			});
			} else {
			  swal("Phone status is not change");
			}
		  })

    }
    const handleWallet_validate = () => {

        swal({
			title: "Are you sure?",
			text:
			  `Once submitted, phone reg. status will change to Validated`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
				
				let trans_amount = profile?.appStatus? 1550:1950
				//let trans_amount = profile?.profileStatus==='M-RIGHT REGISTRAR'? 1100:1500
				//appStatus
				let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString() ,  trans_pay_method: 'wallet', trans_amount: trans_amount, trans_name: 'Phone Validation' }

				deletePhoneAction_ChangeStatusValidated(formData2, navigate).then((res) => {
				
				if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
					swal(`${res}`);
				}else {
						swal(`Phone status has change to Validated!`, {
					icon: "success",
					});
					setAddCard2(false);
					navigate("/my-phones")
					
				}
				
			});
			} else {
			  swal("Phone status is not change");
			}
		  })

    }
    const handleWallet = () => {

        swal({
			title: "Are you sure?",
			text:
			  `Once submitted, phone status will change to ${formData.phoneStatus}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
				let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString() ,  trans_pay_method: 'wallet', trans_amount: 3500, trans_name: 'Declare phone missing ' }

				deletePhoneAction_ChangeStatus(formData2, navigate).then((res) => {
				
				if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
					swal(`${res}`);
				}else {
						swal(`Phone status has change to ${formData.phoneStatus}!`, {
					icon: "success",
					});
					setAddCard(false);
					navigate("/my-phones")
					
				}
				
			});
			} else {
			  swal("Phone status is not change");
			}
		  })

    }


     //Add Submit data
	 const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneStatus === '') {
            errorObj.phoneStatus = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneStatus, "error");
        }
		 if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			swal('Oops', errorObj.password, "error");
        }
		

        setErrors(errorObj);
        if (error) return;

		if(phoneStatus === 'Missing or Stolen') {
			setFormData({ ...formData, trans_pay_method: 'paystack'});
		}

		 phoneStatus === 'Missing or Stolen'? setValidationFlag(true): handleDelete()
		
		//handleDelete()
		
    };
	 const handleValidateFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneid === '') {
            errorObj.phoneid = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneid, "error");
        }


        setErrors(errorObj);
        if (error) return;

		handleWallet_validate()
		
		//handleDelete()
		
    };



	const handleAddFormSubmit2 = ()=> {
     
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneStatus === '') {
            errorObj.phoneStatus = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneStatus, "error");
        }
		 if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			swal('Oops', errorObj.password, "error");
        }
		

        setErrors(errorObj);
        if (error) return;

		 handleWallet()
		
		//handleDelete()
		
    };

	const onChange = (e) =>{
		
		setFormData({ ...formData, [e.target.name]: e.target.value,prof_id: profile._id});
		e.target.value === 'Missing or Stolen'  ? setValidationFlag(true):
		phoneStatus === 'Missing or Stolen' && e.target.name === 'password' ? setValidationFlag(true):setValidationFlag(false)

		//setId(e.target.value);
    }
	//Edit start 
	//const [editModal, setEditModal] = useState(false);	
	// Edit function editable page loop
    const [editContentId, setEditContentId] = useState(null);
   
    // Edit function button click to edit
    const handleEditClick = ( event, content) => {
        event.preventDefault();
		setFormData({ ...formData, phoneStatus:'', phoneid: content._id, 	phoneName:content.phoneName });
       
        setAddCard(true);
    };
    // Edit function button click to edit
    const handleValidateClick = ( event, content) => {
        event.preventDefault();
		setFormData({ ...formData, phoneStatus: content.phoneStatus, phoneid: content._id, phoneName:content.phoneName, phonecolor:content.phonecolor });
       
        setAddCard2(true);
        setRegDate(content.date);
    };
   
    // edit  data  
    const [editFormData, setEditFormData] = useState({
        name:'',
        serviceRate:'',
        serviceDesc:'',
    })
    
    //update data function
    const handleEditFormChange = (event) => {
        event.preventDefault();   
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    };
    
	
    // edit form data submit
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        const editedContent = {
		//	serviceId: id,
            _id: editContentId,
            name: editFormData.name,
            serviceRate: editFormData.serviceRate,
            serviceDesc: editFormData.serviceDesc,
      
        }

	//	createServiceItemAction(editedContent,true);

        //const newList = [...list];
       // const index = list.findIndex((content)=> content.id === editContentId);


        //newList[index] = editedContent;
        //setList(newList);
        setEditContentId(null);
       // setEditModal(false);
	   
    }

	//Cencel button to same data
    const handleCancelClick = () => {
        setEditContentId(null);    
    };
	
    const handlePayFlagClick = () => {
         setPayFlag(!payFlag);    
    };
	

	const payButton = ()=>	{
	  
	  let onlinePay = ''
      if(!payFlag){
		
		//	setFormData({ ...formData, trans_pay_method: 'paystack', trans_amount: 4500, trans_name: 'Declare phone missing '});
		
		
		checked = 'defaultChecked'
			 onlinePay = <PaystackButton className="btn btn-secondary my-1" {...componentProps} />
	  }else{
		checked = ''
		//	setFormData({ ...formData, trans_pay_method: 'wallet', trans_amount: 4500, trans_name: 'Declare phone missing ' });
			onlinePay = <button type="submit" className="btn btn-secondary my-1" onClick={handleAddFormSubmit2}>PAY via Phone Shop Validation -Your Bal is &#8358;{balance}</button>   
	  }
	
		return (<Fragment>
					    <div className="col-xl-6 col-lg-8"> 
							<div className="card">
									<div className="card-header">
										<h4 className="card-title">Service Fee: &#8358;3500</h4>
									</div>
									{/* <div className="card-body">Phone Shop Validation balance- &#8358;{balance}</div> */}
							</div>
						</div>
						<div className="col-xl-6 col-lg-8">
							<div className="card">
								<div className="card-header">
								<h4 className="card-title">Payment Options</h4>
								</div>
								<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
									<div className="form-group mb-0">
										
										
										{	
									
											payFlag   && 
											(<Fragment>
												
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Phone Shop Validation</b>
													</label>
												</div>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Pay Online</b>
													</label>
												</div>
											</Fragment>
											)
	
										}
										{	
									
											!payFlag   && 
											(<Fragment>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Phone Shop Validation</b>
													</label>
												</div>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Pay Online</b>
													</label>
												</div>
											</Fragment>
											)
	
										}


										
										
										
									</div>
									</form>
								</div>
								</div>
							</div>
						</div>
						
						

						<div className="modal-footer">
							{onlinePay}
							<button type="button" onClick={()=> {setAddCard(false);  setPayFlag(true);}  } className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>

						</div>
	
		</Fragment>)
		// return (<Fragment>
		// 			<div className="modal-footer">
		// 			<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
		// 					<button type="button" onClick={()=> {setAddCard(false); setFormData({ ...formData, phoneStatus: ''});}} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
		// 			</div>
	
		// </Fragment>)
	
		};


	const payButton_validate = ()=>	{
	  
	  let onlinePay = ''
      if(!payFlag){
		
		//	setFormData({ ...formData, trans_pay_method: 'paystack', trans_amount: 4500, trans_name: 'Declare phone missing '});
		
		
		checked = 'defaultChecked'
			 onlinePay = <PaystackButton className="btn btn-secondary my-1" {...componentProps} />
	  }else{
		checked = ''
		//	setFormData({ ...formData, trans_pay_method: 'wallet', trans_amount: 4500, trans_name: 'Declare phone missing ' });
			onlinePay = <button type="submit" className="btn btn-secondary my-1" onClick={handleValidateFormSubmit}>PAY via Phone Shop Validation -Your Bal is &#8358;{balance}</button>   
	  }
	
		return (<Fragment>

						<div className="col-xl-6 col-lg-8">
							<div className="card">
								<div className="card-header">
								<h4 className="card-title">Payment Options</h4>
								</div>
								<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
									<div className="form-group mb-0">
										
										
										{	
									
											payFlag   && 
											(<Fragment>
												
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Phone Shop Validation</b>
													</label>
												</div>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Pay Online</b>
													</label>
												</div>
											</Fragment>
											)
	
										}
										{	
									
											!payFlag   && 
											(<Fragment>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Phone Shop Validation</b>
													</label>
												</div>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Pay Online</b>
													</label>
												</div>
											</Fragment>
											)
	
										}


										
										
										
									</div>
									</form>
								</div>
								</div>
							</div>
						</div>
						
						

						<div className="modal-footer">
							{onlinePay}
							<button type="button" onClick={()=> {setAddCard2(false);  setPayFlag(true);}  } className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>

						</div>
	
		</Fragment>)
		// return (<Fragment>
		// 			<div className="modal-footer">
		// 			<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
		// 					<button type="button" onClick={()=> {setAddCard(false); setFormData({ ...formData, phoneStatus: ''});}} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
		// 			</div>
	
		// </Fragment>)
	
		};


	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
			<div className="col-12">
				<Modal className="modal fade"  show={addCard2} onHide={setAddCard2} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Phone Validation</h4>
									<button type="button" className="btn-close" onClick={()=> {setAddCard2(false);setFormData({ ...formData, [phoneStatus]: ''}); }} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">Phone Status</label> */}
												<h4 className="modal-title fs-20">Phone</h4>
												<div className="contact-name">
												{formData.phoneName}
												<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">Phone Status</label> */}
												<h4 className="modal-title fs-20">Phone Color</h4>
												<div className="contact-name">
												{formData.phonecolor}
												<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">Phone Status</label> */}
												<h4 className="modal-title fs-20">Phone Reg.  Status</h4>
												<div className="contact-name">
												<IsExpired date={regDate} /><br />					
													<div className="card-header">
														<h4 className="card-title btn">Phone Validation Fee: &#8358;{profile?.appStatus? 1550:1950}</h4>
													</div>
												</div>
											</div>
										
											
										
											
										</div>
									</div>
								</div>

								
								{/* {!validationFlag &&  (  
								<Fragment>
									<div className="modal-footer">
										<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Change Status</button>   
										<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
									</div>
								</Fragment>
						)}	 */}
							</form>
							{ payButton_validate()	}	
							
						</div>
					</div>
				</Modal>
				<Modal className="modal fade"  show={addCard} onHide={setAddCard} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Change Phone Status</h4>
									<button type="button" className="btn-close" onClick={()=> {setAddCard(false);setFormData({ ...formData, [phoneStatus]: ''}); }} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">Phone Status</label> */}
												<h4 className="modal-title fs-20">Phone Status</h4>
												<div className="contact-name">
												<select name="phoneStatus" value={formData.phoneStatus} className="form-control" onChange={onChange}  >
													<option value="">* Select Phone Status</option>
													<option value="IN USE">IN USE</option>
													<option value="Missing or Stolen">Missing or Stolen</option>
													<option value="Forsale">Forsale</option>
												</select>
													<span className="validation-text"></span>
												</div>
											</div>
											
										{	phoneStatus !== 'Missing or Stolen' && 
											(
											<Fragment>
												<div className="form-group mb-3">
												<label className="text-black font-w500">Login Password</label>
												<div className="contact-name">
												<input type="password"  className="form-control"  autoComplete="off"
														name="password" 
														required="required"
														onChange={onChange}
														placeholder="Password is required"
														
													/>
													
													<span className="validation-text"></span>
												</div>
											</div>
											</Fragment>)}
											
										</div>
									</div>
								</div>

								{!validationFlag &&  (  
								<Fragment>
									<div className="modal-footer">
										<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Change Status</button>   
										<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
									</div>
								</Fragment>
						)}	
							</form>
							{validationFlag &&  payButton()	}	
						</div>
					</div>
				</Modal>
				<div className='row'>
					{/* <div className='col-lg-4 mb-2'>
							<button className="btn btn-warning" onClick={() => navigate(-1)}>go back</button>
					</div> */}
				
					
				</div>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">My Registered Phones</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<form onSubmit={handleEditFormSubmit}>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												{/* <th></th> */}
												<th>Phone Name</th>
												<th>Phone Color</th>
												<th> Condition</th>
												<th>Phone Status</th>
												<th>Date registered</th>
												<th>Reg.  Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>

										{loading ? (
											<Spinner />
											) : (
												<Fragment>

	
											{profile?.phone_detail?.map((content, index)=>(
												<tr key={index}>
													{editContentId === content._id ? 
														( 
															<EditService editFormData={editFormData} 
																handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick}/> 
															) : 
														( 
															<>
																{/* <td><img className="rounded-circle" width="35" src={pic1} alt="" /></td> */}
																<td>{content.phoneName}</td>
																<td>{content.phonecolor}</td>
																<td>{content.phonecondition}</td>
																<td>{content.phoneStatus}<br />{(content.phoneStatus==="Missing or Stolen" || content.phoneStatus==="Forsale"  ) &&
																 (<Link className="btn btn-secondary btn-xxs"
																			onClick={(event)=> handleEditClick(event, content)}
																		>Change this</Link>)}
																</td>
																<td> <TimeAgo timestamp={content.date} /><br />
																	{( !content?.regStatus && <Link className="btn btn-warning btn-xxs"
																			onClick={(event)=> handleValidateClick(event, content)}>
																		Validate this
																	 </Link>)}
																</td>
																<td>{ !content?.regStatus?   (<Fragment><IsExpired date={content.date} /></Fragment>):<span  className="btn btn-secondary my-1" >Validated</span>}</td>
																{/* <td>{content.education}</td>
																<td><Link to={"#"}><strong>{content.mobile}</strong></Link></td>
																<td><Link to={"#"}><strong>{content.email}</strong></Link></td> */}
																<td>
																	<div className="d-flex">
																		<Link to={`/add-phone/`} className="btn btn-warning shadow btn-xs sharp me-2">
																			<i className="fa fa-plus"></i>
																		</Link>
																		
																		<Link to={`/edit-phone/${content._id}`}  className="btn btn-secondary	 shadow btn-xs sharp me-2">
																			<i className="fas fa-pen"></i>
																		</Link>
																		
																		<Link   to={`/delete-phone/`} className="btn btn-danger shadow btn-xs sharp" > 
																			<i className="fa fa-trash"></i>
																		</Link>
																			
																	</div>												
																</td>			
															</>   
														)
													}
												</tr>    
											))}
											  </Fragment>
      )}
										</tbody>
									</table>
								</form>	
							</div>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">My Credits Transfers</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<form onSubmit={handleEditFormSubmit}>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												<th>Sender</th>
												<th>Receiver Name</th>
												<th>Credit</th>
												<th>Date</th>
										
											</tr>
										</thead>
										<tbody>

										{loading ? (
											<Spinner />
											) : (
												<Fragment>

	
											{credits?.map((content, index)=>(
												<tr key={index}>
													{editContentId === content._id ? 
														( 
															<EditService editFormData={editFormData} 
																handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick}/> 
															) : 
														( 
															<>
																{/* <td><img className="rounded-circle" width="35" src={pic1} alt="" /></td> */}
																<td>{content.sender_id.name}</td>
																<td>{content.receiver_id.name}</td>
																<td>{content.amount}</td>
																<td>{content.created_at}</td>
																
															
													
																		
															</>   
														)
													}
												</tr>    
											))}
											  </Fragment>
      )}
										</tbody>
									</table>
								</form>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


MyPhones.propTypes = {

	deletePhoneAction_ChangeStatus: PropTypes.func.isRequired,
	deletePhoneAction_ChangeStatusValidated: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,

  };
  
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,

	name: state.auth.auth.name,
	email: state.auth.auth.email,
	user: state.auth.auth,
	phone: state.auth.auth.phone,

  });
  
  const mapDispatchToProps = {  deletePhoneAction_ChangeStatus,getCurrentProfile, deletePhoneAction_ChangeStatusValidated };
  export default connect(mapStateToProps, mapDispatchToProps)(
	MyPhones
  );