import React, { Fragment, useState,useEffect } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
//import StepThree from "./StepThree";
//import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";
import img1 from "../../../../images/card/1.png";
import img2 from "../../../../images/card/2.png";
import img3 from "../../../../images/card/3.png";
import LogoutPage from '../../../layouts/nav/Logout';
import logo from "../../../../images/logo-full.png";
import { Row, Col, Card, Button, Dropdown, ButtonGroup,Nav } from "react-bootstrap";
import { PaystackButton } from '../../../../dist/index.es';
import { Link, useNavigate,useMatch } from "react-router-dom";
import api from "../../../../utils/api";
import swal from "sweetalert";
import { 
	UserCircle, 
	LucidePhoneIncoming,
	BookHeadphonesIcon,
	Smartphone, 
	AlertTriangle, 
	DollarSign, 
	Trash2, 
	CreditCard, 
	Send, 
	MapPin, 
	ShieldAlert, 
	FileText ,
	Download 
  } from 'lucide-react';


import {
	createProfile,
	getCurrentProfile,
	
  } from "../../../../store/actions/profileAction";
  import {
  
	getStatesLGA,
	getServiceFees
  } from "../../../../store/actions/statesLGAAction";




const initialState = {
	company: "",
	website: "",
	phoneno:"",
	phoneno2:"",
	birthday:"",
	location: "",
	sex: "",
	tnc: false,
	address: "",
	country: "",
	state: "",
	lga: "",
	father: "",
	yourname: "",
	status: "",
	skills: "",
	githubusername: "",
	profileStatus:"MEMBER",
	bio: "",
	twitter: "",
	facebook: "",
	linkedin: "",
	youtube: "",
	instagram: "",
	phoneModel: "",
	phoneNumber:"",
	phoneName: "",
	phoneStatus: "",
	emei: "",
   phonesno: "",
   referralno: "",
   nok: "",
   nokrel: "",
   nokphone1: "",
   nokphone2: ""
  
  };

const Welcome = ({
	profile: { profile, loading },
	createProfile,
	getCurrentProfile,
	stateLGA:{stateLGA,serviceFees},
	getStatesLGA,
	getServiceFees,
	name,
	phone,
	email,
	user,
	app
  
  }) => {
	var sno=false
	var sno2=true
	const [goSteps, setGoSteps] = useState(0);
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const creatingProfile = useMatch("/welcome");
	const [province,setState] = useState('Nigeria')
	const [lgovt,setLgovt] = useState('')
	const [tranxFee,setTranxFee] = useState(0)
	const [tranxDesc,setTranxDesc] = useState('')
	const [amount,setAmount] = useState(1200)
	const [sum,setSum] = useState(0)
	const [serviceFlag,setServiceFlag] = useState(false)
	const [validationFlag,setValidationFlag] = useState(false)
	const [displaySocialInputs, toggleSocialInputs] = useState(false);
	const [displayPhoneInputs, togglePhoneInputs] = useState(false);
	const [feeFlag, setFeeFlag] = useState(false);
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
	//const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_TEST;

	const changeCountry = (e)=> {
		
		setState(e.target.value)
		setFormData({ ...formData, [e.target.name]: e.target.value });
	   }
	 
	 const changeState = (e)=>  {
	   setLgovt(e.target.value)
	   //alert(e.target.value)
	   setFormData({ ...formData, [e.target.name]: e.target.value });
	 }
	 
	 const changeService = (e)=> {
	   const ArrItem = (e.target.value).split('-') 
	   setTranxDesc(ArrItem[0])
	   setTranxFee(ArrItem[1])
	   //setAmount(ArrItem[1]*100)
	  }


	  const ontncChanged = () => 
	{
	  
	  setFormData({ ...formData, tnc:formData.tnc?false:true});
	}
	  const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		//setProfileStatus("MEMBER")
		
		
    	afterValidate()
		console.log('reference',reference);
	};
	
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		//console.log('closed')
	}
	const componentProps = {
		//   ...config,
		reference: (new Date()).getTime().toString(),
		email:email,
		amount:amount*100,
		metadata: {
			name,
			phone,
		  },
		publicKey: publicKey,
		
	   //  lastname: 'story',
		   text: 'Proceed to SUBMIT',
		   onSuccess,
		   onClose
	   };
	  


	useEffect(() => {
		// if there is no profile, attempt to fetch one
		const profileData = { ...initialState };
		!profile && getCurrentProfile();
		if (!loading) {
			getStatesLGA()
			//window.location.reload();
		}
		
		if (loading) {
			
		getServiceFees()
	    setServiceFlag(true)
	
		}
		// if we finished loading and we do have a profile
		// then build our profileData
		
		
		if (!loading && profile) {
		  
		  for (const key in profile) {
			if (key in profileData) profileData[key] = profile[key];
		  }
		  for (const key in profile.social) {
			if (key in profileData) profileData[key] = profile.social[key];
		  }

	

			console.log(profileData)

		  // the skills may be an array from our API response
		  if (Array.isArray(profileData.skills))
			profileData.skills = profileData.skills.join(", ");



		  // set local state with the profileData
		
		  setFormData(profileData);
		  //window.location.reload(true);
		//   if(serviceFlag ){
		// 	//  
		// 	let sum2 = []
		// 	sum2 = [...found]
		// 	//setAmount(sum2[1][1]*100)
		// 	console.log(sum2)
		//   }
		}

if (!profile) {
	
		if ('yourname' in profileData) profileData['yourname'] = name;
		if ('phoneno' in profileData) profileData['phoneno'] = phone;
		setFormData(profileData);
		//console.log(profileData)
		
}

	  }, [loading, profile,getStatesLGA,setServiceFlag,serviceFlag,getServiceFees,name,phone]);
	
	  const {
		company,
		website,
		location,
		country,
		state,
		lga,
		father,
		yourname,
		status,
		skills,
		profileStatus,
		githubusername,
		bio,
		twitter,
		facebook,
		linkedin,
		youtube,
		instagram,
		phoneModel,
		phoneNumber,
		phoneName,
		phoneStatus,
		tnc,
		emei,
	   phonesno,
	   phoneno,
	   phoneno2,
	   birthday,
	   address,
	   sex,
	   referralno,
	   nok,
	   nokrel,
	   nokphone1,
	   nokphone2
	  } = formData;


	  let errorsObj = {		
		company:'',
	  website:'',
	  location:'',
	  country:'',
	  state:'',
	  lga:'',
	  father:'',
	  yourname:'',
	  status:'',
	  skills:'',
	  profileStatus:'',
	  githubusername:'',
	  bio:'',
	  twitter:'',
	  facebook:'',
	  linkedin:'',
	  youtube:'',
	  instagram:'',
	  phoneModel:'',
	  phoneNumber:'',
	  phoneno:'',
	  birthday,
	  phoneno2:'',
	  address:'',
	  sex:'',
	  tnc:'',
	  phoneName:'',
	  phoneStatus:'',
	  emei:'',
	 phonesno:'',
	 referralno:'',
	 nok:'',
	 nokrel:'',
	 nokphone1:'',
	 nokphone2:''
	 };
	 //console.log(formData)
	  const [errors, setErrors] = useState(errorsObj);
	  const onChange = (e) =>  setFormData({ ...formData, [e.target.name]: e.target.value });

	//   const found = Object.entries(serviceFees['services'])
	//   .find(([key,val]) => key=== 0 )

	let found =''
	
  if(serviceFlag){
	
	   found = Object.entries(serviceFees['services'])
	  .find(([key, value]) => value[0] === "New Member Registration Fee" );
	 
	 // setAmount(found[1][1]*100)
  }
//   if(loading){
// 	alert(name)
// 	setFormData({ ...formData, yourname:name, phoneno:phone });
//   }
  
//   if(serviceFlag ){
// 	//  
// 	let sum2 = []
// 	sum2 = [...found]
	
// 	console.log(sum2)
//   }


 
	const checkReferral2 = async(e)=>{

	await api.put(`/profile/phones2`,formData).then((res) => {
		//console.log(res.data)
			if(res.data.isError){
				swal('Oops',"Wrong referral code", {
					icon: "error",
					});
					//alert(sno2)
					sno2=false
				}else{
					setAmount(900)
				setFeeFlag(true)
				sno2=true
				}
				
		}) .catch((error) => {
			console.error(error)
		 });
   }

   const recheckReferral2 = async(e)=>{
	setValidationFlag(false)
	setAmount(1200)
	setGoSteps(0)
   }
  
   function afterValidate() {
			//preventDefault();
			//setFormData({ ...formData, profileStatus: "MEMBER" });
			//alert('created')
			createProfile({ ...formData, profileStatus: "MEMBER" }, navigate, user, profile ? true : false).then(() =>
			navigate("/dashboard")	);
	}

	const recheck = async()=>{

		const phone_ = profile?.phone_detail.find((element) => {
		  return element.regStatus === true;
		  })
  
		if(!phone_){
		  swal("Please validate at least one added phone to unlock this link.\n\n Thank you.", {
			icon: "warning",   });
			navigate("/phone-validate")
		}{
			navigate("/dashboard")
	   }
  

  
		 // else	setAddCard3(true)
  
	  }

	  const redirect_download = async()=>{

		const phone_ = profile?.phone_detail.find((element) => {
		  return element.regStatus === true;
		  })
  
		if(!phone_){
		  swal("Please validate at least one added phone to unlock this download link.\n\n Thank you.", {
			icon: "warning",   });
			navigate("/phone-validate")
		}else{
			 navigate("/download-page")
		}
  

  
		 // else	setAddCard3(true)
  
	  }

	const  onSubmit = async (e)=> {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (sex === '') {
            errorObj.sex = 'Sex is Required';
            error = true;
			swal('Oops', errorObj.sex, "error");
        }
        

			// var bday=birthday;
			// bday=bday.split("-");
			// var bday_in_milliseconds = new Date(parseInt(bday[0], 10), parseInt(bday[1], 10) - 1 , parseInt(bday[2]), 10).getTime(); //birth-date in milliseconds
			// var now = new Date().getTime(); //current time in milliseconds
			// if(now - bday_in_milliseconds < 567648000000){ //567648000000 is 18 years in milliseconds
			// 	errorObj.birthday = 'Member under 18years is not allowed.';
			// 	error = true;
			// 	swal('Oops', errorObj.birthday, "error");
			// }

	
  
        if (country === '') {
            errorObj.country = 'Country is Required';
            error = true;
			swal('Oops', errorObj.country, "error");
        }

		 if (state === '') {
            errorObj.state = 'State is Required';
            error = true;
			swal('Oops', errorObj.state, "error");
        }
		 if (lga === '') {
            errorObj.lga = 'LGA is Required';
            error = true;
			swal('Oops', errorObj.lga, "error");
        }
		//  if (father === '') {
        //     errorObj.father = 'Father is Required';
        //     error = true;
		// 	swal('Oops', errorObj.father, "error");
        // }
		 if (yourname === '') {
            errorObj.yourname = 'Your name is Required';
            error = true;
			swal('Oops', errorObj.yourname, "error");
        }
		//  if (status === '') {
        //     errorObj.status = 'Status is Required';
        //     error = true;
		// 	swal('Oops', errorObj.status, "error");
        // }
		 if (phoneno === '') {
            errorObj.phoneno = 'Phone no is Required';
            error = true;
			swal('Oops', errorObj.phoneno, "error");
        }
		 if (address === '') {
            errorObj.address = 'Address is Required';
            error = true;
			swal('Oops', errorObj.address, "error");
        }
		 if (sex === '') {
            errorObj.sex = 'Sex is Required';
            error = true;
			swal('Oops', errorObj.sex, "error");
        }
		 if (!tnc) {
            errorObj.tnc = 'Terms & Conditions is Required';
            error = true;
			swal('Oops', errorObj.tnc, "error");
        }

        setErrors(errorObj);
        if (error) return;
		referralno && await checkReferral2()

	
			if( referralno !=="" )
				if(!sno2) return;

			!app && setValidationFlag(true);

			if (app) {
				createProfile({ ...formData, profileStatus: "MEMBER" }, navigate, user, profile ? true : false).then(() =>
				navigate("/dashboard")	);
			}


			//afterValidate()
	
	
	///	if (!referralno) setValidationFlag(true);

		// if(creatingPhone) {
			
			// api.put(`/profile/check-referral`,{referralno}).then((res) => {
			// 	console.log(res.data)
			// 	if(res.data.isError){
					
			// 		if(res.data.msg.errors[0].msg ===`another profile` ){
			// 			swal('Oops',"This phone has already been registered. Search it in phone page to confirm. If you genuinely buy it but not deleted by the owner, please call h(im)er to do so before paying for the phone..\n\n If persist, please send complain to M-right with the detail of the phone to resolve the matter", {
			// 			icon: "error",
			// 			});
			// 		}else{
			// 			swal('Oops', `${res.data.msg.errors[0].msg}` , {
			// 				icon: "error",
			// 				});

			// 		}
					
			
			// 		// dispatch({
			// 		//   type: UPDATE_PROFILE,
			// 		//   payload: res.data
			// 		// });
					
			// 	//navigate("/dashboard")
			// 	}else{
			// 		setFeeFlag(true)
			// 		setAmount(700)
			// 		setValidationFlag(true);
			// 	}
		  
			// }) .catch((error) => {
			// 	console.error(error)
			//  });
			
		
		
		//}




		//setFormData({ ...formData, profileStatus: "MEMBER" });
      //  dispatch(loadingToggleAction(true));
       // dispatch(signupAction({ name, email, phone, password }, navigate));
	//    createProfile(formData, profile ? true : false).then(() =>
	//    navigate("/dashboard")
	//  );

    }

	return (
		
		<Fragment>


		

<div className="row justify-content-center h-100 align-items-center" >
{/* <PageTitle activeMenu="Phone Anti-theft System" pageContent="Card" motherMenu="MRight" /> */}
				<div className="col-xl-10 col-xxl-10">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title"><img src={logo} alt="" width='250'height='70'/></h4>
							{/* <h4 className="card-title">Welcome! </h4>  */}
							<h4 className="card-title"> <LogoutPage /></h4>
						</div>
						{/* <h4 className="card-title"> </h4> */}
						
						{ user?.profileStatus !=='MEMBER' &&(
								<div className="card-body">
									<div className="text-center mb-3">
									<br /><div className="card-title"><strong>Welcome! </strong> {name}</div>Status: <Button className="me-2" variant="warning btn-xs">
							PENDING
							</Button><br /><br />
							{/* {(referralno && feeFlag) &&  (  
								<Fragment>
									<div>&#8358;{amount}</div> 
								</Fragment>
								)}
								
								{!referralno &&  (  
								<Fragment>
									<div>&#8358;{amount}</div> 
								</Fragment>
								)} */}


								{/* {creatingProfile ? "Create Your Profile" : "Edit Your Profile"} */}
								
                   		 </div>



					<form onSubmit={(e) => onSubmit(e)}>

						<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									{/* <Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
								</Stepper>
							  {goSteps === 0 && (
								<>
									
									<StepOne 
									
									formData={formData} 
								
									onChange={onChange} 
									changeCountry={changeCountry}
									changeState={changeState}
									changeService={changeService}
									creatingProfile={creatingProfile}
									displaySocialInputs={displaySocialInputs}
									toggleSocialInputs={toggleSocialInputs}
									stateLGA={stateLGA}
									province={province}
									serviceFlag={serviceFlag}
									lgovt={lgovt}
									ontncChanged={ontncChanged}
									
									/>	
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-next" onClick={() => setGoSteps(1)}>Next</button>
									</div>	
								</>
							  )}
							  {/* {goSteps === 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )} */}
							  {goSteps === 1 && (
								<>
									
									<StepTwo 
									formData={formData} 
									onChange={onChange} 
									changeCountry={changeCountry}
									changeState={changeState}
									changeService={changeService}
									creatingProfile={creatingProfile}
									displaySocialInputs={displaySocialInputs}
									toggleSocialInputs={toggleSocialInputs}
									stateLGA={stateLGA}
									province={province}
									serviceFlag={serviceFlag}
									lgovt={lgovt}
									validationFlag={validationFlag}
									/>
									<div className="text-end toolbar toolbar-bottom p-2">
										
										<button  className="btn btn-warning sw-btn-prev me-1"onClick={() => recheckReferral2()}>Prev</button>
										
										

										{!validationFlag &&  (  
        									  <Fragment>						
												<button className="btn btn-secondary sw-btn-next ms-1" >submit</button>
											</Fragment>
           									)}	

									</div>	
								</>	
							  )}
							  
							</div>
						</form>
						{validationFlag &&  (  
        									  <Fragment>
												<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
											</Fragment>
           									  )}	
						</div>
						)}


							{    user?.profileStatus ==='MEMBER' &&
							
							<>
								<div className="card-body">
									<div className="text-center mb-3">
												<br /><div className="card-title"><strong>Welcome! </strong> {name}</div>Status: <Button className="me-2" variant="warning btn-xs">
										{user?.profileStatus}
										</Button><Button className="me-4" variant="success btn-xs">
										<Link to="/dashboard">Dashboard</Link>
										</Button><br /><br />
								
									</div>
								</div>

								{!user?.asg  &&
								<Fragment className=" mb-3">
										<Row className="justify-content-center">
        <Col xl={10}>
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="text-center mb-4">Major Links</Card.Title>
                  <Nav className="flex-column align-items-start">
                    <Nav.Link className="text-blue w-100" href="/user-profile/">
                      <UserCircle size={18} className="me-2" />User profile
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" href="/add-phone/">
                      <BookHeadphonesIcon size={18} className="me-2" />Add phone
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" href="/my-phones/">
                      <Smartphone size={18} className="me-2" />My phones
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" href="/phone-missing/">
                      <AlertTriangle size={18} className="me-2" />Declare phone missing
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" href="/phone-forsale/">
                      <DollarSign size={18} className="me-2" />Declare phone for sale
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" href="/delete-phone/">
                      <Trash2 size={18} className="me-2" />Delete phone
                    </Nav.Link>
                  </Nav>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="text-center mb-4">Other Links</Card.Title>
                  <Nav className="flex-column align-items-start">
                    <Nav.Link className="text-blue w-100" href="/buy-credit/">
                      <CreditCard size={18} className="me-2" />Buy Bulk Credit
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" href="/transfer-credit/">
                      <Send size={18} className="me-2" />Transfer/validate customer phone
                    </Nav.Link>
					<Nav.Link className="text-blue w-100" onClick={() => redirect_download()}>
                      <Download size={18} className="me-2" />Download Antitheft Apps
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" onClick={() => recheck()}>
                      <MapPin size={18} className="me-2" />Track lost phone
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" disabled onClick={() => redirect_download()}>
                      <ShieldAlert size={18} className="me-2" />Report phone case to police
                    </Nav.Link>
                    <Nav.Link className="text-blue w-100" disabled onClick={() => recheck()}>
                      <FileText size={18} className="me-2" />Report other case to police
                    </Nav.Link>
                  </Nav>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
    								  </Row>
 						 	  </Fragment>
							  
							  }
							</>
							
							}
					
					</div>
				</div>
				<div>
					
				</div>
			</div>

			
		</Fragment>
	);
};





Welcome.propTypes = {
	createProfile: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	stateLGA: PropTypes.object.isRequired,
	getStatesLGA: PropTypes.func.isRequired,
	getServiceFees: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	stateLGA: state.statesLGAReducer,
	// errorMessage: state.auth.errorMessage,
	// successMessage: state.auth.successMessage,
	// showLoading: state.auth.showLoading,
	name: state.auth.auth.name,
	phone: state.auth.auth.phone,
	email: state.auth.auth.email,
	user: state.auth.auth,
	app: state.auth.auth.app,


  });
  const mapDispatchToProps = { createProfile, getCurrentProfile,getStatesLGA,getServiceFees };
  export default connect(mapStateToProps, mapDispatchToProps)(Welcome);