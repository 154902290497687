import React, { useContext } from "react";
import { useSelector } from 'react-redux'
/// React router dom
import {  Routes, Route, Outlet, useNavigate  } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import GuestList from "./components/Dashboard/GuestList";
import GuestDetails from "./components/Dashboard/GuestDetails";
import StaffList from "./components/Dashboard/ConciergeList";
import RoomList from "./components/Dashboard/RoomList";
import CreateInvoice from "./components/Dashboard/CreateInvoice";
import Rooms from "./components/Dashboard/Rooms";
import Reviews from "./components/Dashboard/Reviews";
import Task from "./components/Dashboard/Task";

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";
import Theme3 from "./components/Dashboard/Demo/Theme3";
import Theme4 from "./components/Dashboard/Demo/Theme4";
import Theme5 from "./components/Dashboard/Demo/Theme5";
import Theme6 from "./components/Dashboard/Demo/Theme6";


/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import PostPage from "./components/AppsMenu/AppProfile/PostPage";
import CreateUser from "./components/AppsMenu/AppProfile/CreateUser";
import EditUser from "./components/AppsMenu/AppProfile/CreateUser";
 import Alert from "./components/AppsMenu/AppProfile/Alert";
import MultiSelect from "./components/AppsMenu/AppProfile/MultiSelect";




/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
//import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
//import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
//import Registrars from "./components/table/Registrars";
import FilteringTable2 from "./components/table/FilteringTable/FilteringTable2";
import FilteringTable3 from "./components/table/FilteringTable/FilteringTable3";
import FilteringTable4 from "./components/table/FilteringTable4";
import DataTable from "./components/table/DataTable";
import Registrars from "./components/table/Registrars";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";
import Welcome from "./components/Forms/Wizard/Welcome";
import RegisterPhone from "./components/Forms/Wizard/RegisterPhone";
import TransferCredit from "./components/Forms/Wizard/TransferCredit";
import AddCredit from "./components/Forms/Wizard/AddCredit";
import BuyCredit from "./components/Forms/Wizard/BuyCredit";
import Profile from "./components/Forms/Wizard/UserProfile";


/// Pages
import LockScreen from "./pages/LockScreen";

import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
 //import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import Services from "./pages/Services";
import Clients from "./pages/Clients";
import Notes from "./pages/Notes";
import MyPhones from "./pages/MyPhones";
import Registration from "./pages/Registration";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import DeletePhone from "./pages/DeletePhone";
import StolenPhone from "./pages/StolenPhone";
import DownloadPage from "./pages/DownloadPage";
import Registrar from "./pages/Registrar";
import Registrar_exco from "./pages/Registrar_exco";
import ForsalePhone from "./pages/ForsalePhone";


const Markup = ({isAuth, status}) => {
  // const { menuToggle } = useContext(ThemeContext);
  const navigate = useNavigate();
  const routes_admin=[ 
    
    { url: "Staff-list", component: <StaffList/> },
    { url: "CreateUser/", component: <CreateUser/> },
    { url: "transfer-credit", component: <TransferCredit/> },
    { url: "EditUser/:id", component: <EditUser/> },
    
    { url: "MultiSelect", component: <MultiSelect/> },
    { url: "Registrars", component: <Registrars/> },
    // { url: "registrar", component: <Registrar/> },
    { url: "add-credit", component: <AddCredit/> },
      // { url: 'table-registrars', component: <FilteringTable/> },
	  { url: 'table-excos', component: <FilteringTable2/> },
	  { url: 'table-members', component: <FilteringTable4/> },
    { url: 'table-sorting', component: <SortingTable/> },
    { url: "table-datatable-basic", component: <DataTable/> },
  ]

  const routes_ampat=[
    { url: "registrar_exco", component: <Registrar_exco/> },
    { url: "Registrars", component: <Registrars/> },
    
      { url: 'table-registrars', component: <FilteringTable/> },
	  { url: 'table-excos', component: <FilteringTable2/> },
	  { url: 'table-members', component: <FilteringTable4/> },
    { url: 'table-sorting', component: <SortingTable/> },
    { url: "table-datatable-basic", component: <DataTable/> },
  ]

  const routes_member=[

    { url: "form-wizard", component: <Wizard/> },
    { url: "add-phone", component: <RegisterPhone/> },
    { url: "transfer-credit", component: <TransferCredit/> },
   { url: "registrar", component: <Registrar/> },
    { url: "buy-credit", component: <BuyCredit/> },
    { url: "edit-phone/:id", component: <RegisterPhone/> },
    { url: "delete-phone", component: <DeletePhone/> },
    { url: "download-page", component: <DownloadPage/> },
    { url: "phone-missing", component: <StolenPhone/> },
    { url: "phone-validate", component: <StolenPhone/> },
    { url: "phone-forsale", component: <ForsalePhone/> },
    { url: "my-phones", component: <MyPhones/> },


  ]
  const routes_common=[

    { url: "", component: <Home/> },
    { url: "dashboard", component: <Home/> },
    { url: "dashboard-dark", component: <DashboardDark/> },
    { url: "Alert", component: <Alert/> },
    { url: "uc-select2", component: <Select2 /> },
    { url: "user-profile", component: <Profile/> },
    { url: "page-register", component: <Registration/> },
    { url: "page-login", component: <Login /> },
    { url: "page-forgot-password", component: <ForgotPassword/> },
  ]

   let other = []

  if( !status){
    other = [...routes_member]
  }

  if( status==='DSF@NR'){
    other = [...routes_admin]
  }

  if( status==='TAPMA@NR'){
    other = [...routes_ampat]
  }

  const allroutes = [
    /// Dashboard
    
      ...routes_common,
     ...other

    // { url: "guest-list", component: <GuestList/> },
    // { url: "guest-details", component: <GuestDetails/> },
   
    // { url: "room-list", component: <RoomList/> },
    // { url: "rooms", component: <Rooms/> },
    // { url: "reviews", component: <Reviews/> },
    // { url: "task", component: <Task/> },
	
	///Demo
    // { url: "primary-sidebar", component: <Theme1/> },
    // { url: "mini-primary-sidebar", component: <Theme2/> },
    // { url: "compact-primary-header", component: <Theme3/> },
    // { url: "horizontal-primary-sidebar", component: <Theme4/> },
    // { url: "horizontal-modern-sidebar", component: <Theme5/> },
    // { url: "modern-sidebar", component: <Theme6/> },
	
	/// Apps
    // { url: "app-profile", component: <AppProfile/> },
    // { url: "post-details", component: <PostDetails/> },
    // { url: "PostPage", component: <PostPage/> },

    

    // { url: "email-compose", component: <Compose/> },
    // { url: "email-inbox", component: <Inbox/> },
    // { url: "email-read", component: <Read/> },
    // { url: "app-calender", component: <Calendar/> },

    /// Chart
    // { url: "chart-sparkline", component: <SparklineChart/> },
    // { url: "chart-chartjs", component: <ChartJs/> },
    //{ url: "chart-chartist", component: Chartist },
    // { url: "chart-apexchart", component: <ApexChart/> },
    // { url: "chart-rechart", component: <RechartJs/> },

    /// Bootstrap
    // { url: "ui-alert", component: <UiAlert/> },
    // { url: "ui-badge", component: <UiBadge/> },
    // { url: "ui-button", component: <UiButton/> },
    // { url: "ui-modal", component: <UiModal/> },
    // { url: "ui-button-group", component: <UiButtonGroup/> },
    // { url: "ui-accordion", component: <UiAccordion/> },
    // { url: "ui-list-group", component: <UiListGroup/> },
    // { url: "ui-card", component: <UiCards/> },
    // { url: "ui-carousel", component: <UiCarousel/> },
    // { url: "ui-dropdown", component: <UiDropDown/> },
    // { url: "ui-popover", component: <UiPopOver/> },
    // { url: "ui-progressbar", component: <UiProgressBar/> },
    // { url: "ui-tab", component: <UiTab/> },
    // { url: "ui-pagination", component: <UiPagination/> },
    // { url: "ui-typography", component: <UiTypography/> },
    // { url: "ui-grid", component: <UiGrid/> },
	
    /// Plugin
  
    //{ url: "uc-nestable", component: Nestable },
    //{ url: "uc-noui-slider", component: MainNouiSlider },
    // { url: "uc-sweetalert", component: <MainSweetAlert/> },
    // { url: "uc-toastr", component: <Toastr/> },
    // { url: "map-jqvmap", component: <JqvMap/> },
    // { url: "uc-lightgallery", component: <Lightgallery/> },

	///Redux
	// { url: "todo", component: <Todo/> },
	//{ url: "redux-form", component: ReduxForm },
  //{ url: "redux-wizard", component: WizardForm },
	
    /// Widget
    // { url: "widget-basic", component: <Widget/> },

    /// Shop
  //   { url: "ecom-product-grid", component: <ProductGrid/> },
  //   { url: "ecom-product-list", component: <ProductList/> },
  //   { url: "ecom-product-detail", component: <ProductDetail/> },
  //   { url: "ecom-product-order", component: <ProductOrder/> },
  //   { url: "ecom-checkout", component: <Checkout/> },
  //  { url: "ecom-invoice", component: <Invoice/> },
  //   { url: "ecom-customers", component: <Customers/> },

    /// Form
    // { url: "form-element", component: <Element/> },

   
    
  
    // { url: "form-ckeditor", component: <CkEditor/> },
    // { url: "form-pickers", component: <Pickers/> },
    // { url: "form-validation", component: <FormValidation/> },

    /// table
	  // { url: 'table-registrars', component: <FilteringTable/> },
	  // { url: 'table-excos', component: <FilteringTable2/> },
	  // { url: 'table-members', component: <FilteringTable4/> },
    // { url: 'table-sorting', component: <SortingTable/> },
    // { url: "table-datatable-basic", component: <DataTable/> },
    // { url: "table-bootstrap-basic", component: <BootstrapTable/> },
    

    /// pages
   
    //{ url: "page-lock-screen", component: LockScreen },

    // { url: "page-error-400", component: Error400 },
    // { url: "page-error-403", component: Error403 },
    // { url: "page-error-404", component: Error404 },
    // { url: "page-error-500", component: Error500 },
    // { url: "page-error-503", component: Error503 },
 
    // { url: "Services", component: <Services/> },
    // { url: "Clients", component: <Clients/> },
    // { url: "Notes", component: <Notes/> },
    // { url: "CreateInvoice", component: <CreateInvoice/> },
  ];
 
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>     
      
       <Routes>
          {/* <Route path='page-lock-screen' element= {<LockScreen />} /> */}
          {/* <Route path='page-error-400' element={<Error400/>} /> */}
          <Route path='page-error-403' element={<Error403/>} />
          {/* <Route path='page-error-404' element={<Error404/>} /> */}
          {/* <Route path='page-error-500' element={<Error500/>} />
          <Route path='page-error-503' element={<Error503/>} /> */}
          <Route path='welcome' element={<Welcome/>} />
        
      

          <Route  element={<MainLayout />} > 
              {allroutes.map((data, i) => (
                <Route replace
                  key={i}
                  exact
                  path={`${data.url}`}
                  element={data.component}
                />
              ))}
          </Route>
          <Route path='/*' element={<PrivateRoute component={Welcome} other={Error403} isAuth={isAuth} path={path} />} />
          </Routes>
        <Setting />
	      <ScrollToTop />
    </>
  );
};


const PrivateRoute = ({
  component: Component,
  other: Other,
  isAuth,
  path
}) => {
  const navigate = useNavigate();
  //if (path ==='login') return <Component />;

  return isAuth? path ==='login'?<Component />:<Other />   :navigate('/login');
};

export const isProfileStatus = (state) => {

  const status = state.auth.auth.profileStatus
  if(status) {
  // console.log(`${status}`)
      if( status==='MEMBER' )return true;

  }

  return false;
};

function MainLayout(){
  const navigate = useNavigate();
  //window.location.reload(true);
  
  const isMember = useSelector(isProfileStatus)
  if(!isMember) navigate("/welcome")
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);

  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>  
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />                
          </div>
      </div>
      <Footer />
    </div>
  )

};

export default Markup;