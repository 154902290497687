export const MenuList = [
    //Dashboard
    {
        title: 'Main page',	
        iconStyle: <i className="fas fa-clone"></i>,
        to: 'welcome',
    },
       
            {
                title: 'User Profile',
                iconStyle: <i className="fas fa-user-check"></i>,
                to: 'user-profile',					
            },
            {
                title: 'Add Phone',
                iconStyle: <i className="fas fa-clone"></i>,
                to: 'add-phone',					
            },
            {
                title: 'Transfer / Validate Customer\'s phone',
                iconStyle: <i className="fas fa-clone"></i>,
                to: 'transfer-credit',					
            },

            {
                title: 'Buy Bulk Credit',
                iconStyle: <i className="fa-info-circle"></i>,
                to: 'buy-credit',					
            },
           
            {
                title: 'My Phones',
              
                iconStyle: <i className="fas fa-table"></i>,
                to: 'my-phones',					
            },
            {
                title: 'Delete Phone',
                iconStyle: <i className="fas fa-info-circle"></i>,
                to: 'delete-phone',					
            },
            {
                title: 'Declare Phone Missing',
                iconStyle: <i className="fas fa-file"></i>,
                to: 'phone-missing',					
            },
            {
                title: 'Declare Phone for sale',
                iconStyle : <i className="fas fa-heart"></i>,
                to: 'phone-forsale',					
            },
            {
                title: 'Download antitheft app, other apps',
                iconStyle : <i className="fas fa-phone"></i>,
                to: 'download-page',					
            },
           
            // {
            //     title: 'Apply for Membership registrar',
            //     iconStyle: <i className="fas fa-file-alt"></i>,
            //     to: 'registrar',					
            // },
            // {
            //     title: 'Apply as Union Exco',
               
            //     iconStyle: <i className="fas fa-user-check"></i>,
            //     to: 'registrar_exco',					
            // },
            // {
            //     title: 'Typography',
            //     to: 'ui-typography',					
            // },
            // {
            //   title: 'Button',
            //               to: 'ui-button',					
            //               },
            // {
            //     title: 'Users List',
            //     to: 'concierge-list',                
            // },  {
            //     title: 'Create User',
            //     to: 'CreateUser'
            // },		

            
       
   
        //Boosttrap
        // {
        //     title: 'Bootstrap',	
        //     classsChange: 'mm-collapse',
        //     iconStyle: <i className="fab fa-bootstrap"></i>,	
        //     content: [
        //         {
        //             title: 'Accordion',
        //             to: 'ui-accordion',					
        //         },
        //         {
        //             title: 'Alert',
        //             to: 'ui-alert',					
        //         },
        //         {
        //             title: 'Badge',
        //             to: 'ui-badge',					
        //         },
        //         {
        //             title: 'Button',
        //             to: 'ui-button',					
        //         },
        //         {
        //             title: 'Modal',
        //             to: 'ui-modal',					
        //         },
        //         {
        //             title: 'Button Group',
        //             to: 'ui-button-group',					
        //         },
        //         {
        //             title: 'List Group',
        //             to: 'ui-list-group',					
        //         },
        //         {
        //             title: 'Cards',
        //             to: 'ui-card',					
        //         },
        //         {
        //             title: 'Carousel',
        //             to: 'ui-carousel',					
        //         },
        //         {
        //             title: 'Dropdown',
        //             to: 'ui-dropdown',					
        //         },
        //         {
        //             title: 'Popover',
        //             to: 'ui-popover',					
        //         },
        //         {
        //             title: 'Progressbar',
        //             to: 'ui-progressbar',					
        //         },
        //         {
        //             title: 'Tab',
        //             to: 'ui-tab',					
        //         },
        //         {
        //             title: 'Typography',
        //             to: 'ui-typography',					
        //         },
        //         {
        //             title: 'Pagination',
        //             to: 'ui-pagination',					
        //         },
        //         {
        //             title: 'Grid',
        //             to: 'ui-grid',					
        //         },
        //     ]
        // },
//    //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-file-alt"></i>,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: 'form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: 'form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: 'form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: 'form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: 'form-validation',
    //         },

    //     ]
    // },

    //Pages
    // {
    //     title:'Settings',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-clone"></i>,
    //     content : [
          
    //         {
    //                 title:'Services',
    //                 to: 'Services',
    //         },
    //         {
    //                 title:'Members / Agents',
    //                 to: 'Clients',
    //         },
    //         {
    //                 title:'Members Notes',
    //                 to: 'Notes',
    //         },
           
    //         {
    //                 title:'Create Invoice',
    //                 to: 'CreateInvoice',
    //         },
    //          {
    //                 title:'My Profile',
    //                 to: 'Services',
    //         },
    //          {
    //                 title:'Add/Edit Work Exp.',
    //                 to: 'Services',
    //         },
    //          {
    //                 title:'Add/Edit Education History',
    //                 to: 'Services',
    //         },
            

    //     ]
    // },         
     //     //Table
    //  {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-table"></i>,
    //     content : [
    //         // {
    //         //     title:'Table Filtering',
    //         //     to: 'table-filtering',
    //         // },
    //         {
    //             title:'Data Table',
    //             to: 'table-datatable-basic',
    //         },
    //         // {
    //         //     title:'Registrars',
    //         //     to: 'Registrars',
    //         // },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    
    
]
export const MenuList2 = [
    //Dashboard
 
    {
        title: 'Dashboard',	
        iconStyle: <i className="fas fa-clone"></i>,
        to: 'dashboard',
    },
    

        // {
        // title:'Registrars',
        // to: 'Clients',
        // },
        // {
        // title:'Excos',
        // to: 'Clients',
        // }, 
        {
            title: 'Transfer Validation',
            iconStyle: <i className="fas fa-clone"></i>,
            to: 'transfer-credit',					
        },
        {
            title: 'Add Credit',
            iconStyle: <i className="fas fa-clone"></i>,
            to: 'add-credit',					
        },
         {
            title:'Agent',
            iconStyle: <i className="fas fa-file-alt"></i>,
            to: 'Registrars',
        },
        //  {
        //     title:'Excos',
        //     iconStyle: <i className="fab fa-bootstrap"></i>,	
        //     to: 'table-Excos',
        // },
         {
            title:'Members',
            iconStyle: <i className="fas fa-table"></i>,
            to: 'table-members',
        },
        // {
        //     title:'Searches Log',
        //     to: 'table-search',
        // },
        // {
        // title:'Transactions',
        // to: 'Clients',
        // },

 //Boosttrap
        // {
        //     title: 'Bootstrap',	
        //     classsChange: 'mm-collapse',
        //     iconStyle: <i className="fab fa-bootstrap"></i>,	
        //     content: [
        //         {
        //             title: 'Accordion',
        //             to: 'ui-accordion',					
        //         },
        //         {
        //             title: 'Alert',
        //             to: 'ui-alert',					
        //         },
        //         {
        //             title: 'Badge',
        //             to: 'ui-badge',					
        //         },
        //         {
        //             title: 'Button',
        //             to: 'ui-button',					
        //         },
        //         {
        //             title: 'Modal',
        //             to: 'ui-modal',					
        //         },
        //         {
        //             title: 'Button Group',
        //             to: 'ui-button-group',					
        //         },
        //         {
        //             title: 'List Group',
        //             to: 'ui-list-group',					
        //         },
        //         {
        //             title: 'Cards',
        //             to: 'ui-card',					
        //         },
        //         {
        //             title: 'Carousel',
        //             to: 'ui-carousel',					
        //         },
        //         {
        //             title: 'Dropdown',
        //             to: 'ui-dropdown',					
        //         },
        //         {
        //             title: 'Popover',
        //             to: 'ui-popover',					
        //         },
        //         {
        //             title: 'Progressbar',
        //             to: 'ui-progressbar',					
        //         },
        //         {
        //             title: 'Tab',
        //             to: 'ui-tab',					
        //         },
        //         {
        //             title: 'Typography',
        //             to: 'ui-typography',					
        //         },
        //         {
        //             title: 'Pagination',
        //             to: 'ui-pagination',					
        //         },
        //         {
        //             title: 'Grid',
        //             to: 'ui-grid',					
        //         },
        //     ]
        // },
//Forms
// {
//     title:'Forms',
//     classsChange: 'mm-collapse',
//     iconStyle: <i className="fas fa-file-alt"></i>,
//     content : [
//         {
//             title:'Form Elements',
//             to: 'form-element',
//         },
//         {
//             title:'Wizard',
//             to: 'form-wizard',
//         },
//         {
//             title:'CkEditor',
//             to: 'form-ckeditor',
//         },
//         {
//             title:'Pickers',
//             to: 'form-pickers',
//         },
//         {
//             title:'Form Validate',
//             to: 'form-validation',
//         },

//     ]
// },
 //     //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-table"></i>,
    //     content : [
    //         // {
    //         //     title:'Table Filtering',
    //         //     to: 'table-filtering',
    //         // },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    //Pages
    {
        title:'Settings',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fas fa-clone"></i>,
        content : [
          
              
            // {
            //         title:'Create Invoice',
            //         to: 'CreateInvoice',
            // },
            {
                title: 'Users List',
                to: 'staff-list',                
            },  
            {
                title: 'Create User',
                to: 'CreateUser'
            },	
            

        ]
    },         
    
    
    
]
export const MenuList3 = [
    //Dashboard
 
    {
        title: 'Dashboard',	
        iconStyle: <i className="fas fa-clone"></i>,
        to: 'dashboard',
    },
    

        // {
        // title:'Registrars',
        // to: 'Clients',
        // },
        // {
        // title:'Excos',
        // to: 'Clients',
        // }, 
        // {
        //     title: 'Transfer Validation',
        //     iconStyle: <i className="fas fa-clone"></i>,
        //     to: 'transfer-credit',					
        // },
        // {
        //     title: 'Add Credit',
        //     iconStyle: <i className="fas fa-clone"></i>,
        //     to: 'add-credit',					
        // },
         {
            title:'Your state Agents',
            iconStyle: <i className="fas fa-file-alt"></i>,
            to: 'Registrars',
        },
        //  {
        //     title:'Excos',
        //     iconStyle: <i className="fab fa-bootstrap"></i>,	
        //     to: 'table-Excos',
        // },
        //  {
        //     title:'Members',
        //     iconStyle: <i className="fas fa-table"></i>,
        //     to: 'table-members',
        // },
        // {
        //     title:'Searches Log',
        //     to: 'table-search',
        // },
        // {
        // title:'Transactions',
        // to: 'Clients',
        // },

 //Boosttrap
        // {
        //     title: 'Bootstrap',	
        //     classsChange: 'mm-collapse',
        //     iconStyle: <i className="fab fa-bootstrap"></i>,	
        //     content: [
        //         {
        //             title: 'Accordion',
        //             to: 'ui-accordion',					
        //         },
        //         {
        //             title: 'Alert',
        //             to: 'ui-alert',					
        //         },
        //         {
        //             title: 'Badge',
        //             to: 'ui-badge',					
        //         },
        //         {
        //             title: 'Button',
        //             to: 'ui-button',					
        //         },
        //         {
        //             title: 'Modal',
        //             to: 'ui-modal',					
        //         },
        //         {
        //             title: 'Button Group',
        //             to: 'ui-button-group',					
        //         },
        //         {
        //             title: 'List Group',
        //             to: 'ui-list-group',					
        //         },
        //         {
        //             title: 'Cards',
        //             to: 'ui-card',					
        //         },
        //         {
        //             title: 'Carousel',
        //             to: 'ui-carousel',					
        //         },
        //         {
        //             title: 'Dropdown',
        //             to: 'ui-dropdown',					
        //         },
        //         {
        //             title: 'Popover',
        //             to: 'ui-popover',					
        //         },
        //         {
        //             title: 'Progressbar',
        //             to: 'ui-progressbar',					
        //         },
        //         {
        //             title: 'Tab',
        //             to: 'ui-tab',					
        //         },
        //         {
        //             title: 'Typography',
        //             to: 'ui-typography',					
        //         },
        //         {
        //             title: 'Pagination',
        //             to: 'ui-pagination',					
        //         },
        //         {
        //             title: 'Grid',
        //             to: 'ui-grid',					
        //         },
        //     ]
        // },
//Forms
// {
//     title:'Forms',
//     classsChange: 'mm-collapse',
//     iconStyle: <i className="fas fa-file-alt"></i>,
//     content : [
//         {
//             title:'Form Elements',
//             to: 'form-element',
//         },
//         {
//             title:'Wizard',
//             to: 'form-wizard',
//         },
//         {
//             title:'CkEditor',
//             to: 'form-ckeditor',
//         },
//         {
//             title:'Pickers',
//             to: 'form-pickers',
//         },
//         {
//             title:'Form Validate',
//             to: 'form-validation',
//         },

//     ]
// },
 //     //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-table"></i>,
    //     content : [
    //         // {
    //         //     title:'Table Filtering',
    //         //     to: 'table-filtering',
    //         // },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    //Pages
    // {
    //     title:'Settings',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-clone"></i>,
    //     content : [
          
              
    //         // {
    //         //         title:'Create Invoice',
    //         //         to: 'CreateInvoice',
    //         // },
    //         {
    //             title: 'Users List',
    //             to: 'staff-list',                
    //         },  
    //         {
    //             title: 'Create User',
    //             to: 'CreateUser'
    //         },	
            

    //     ]
    // },         
    
    
    
]


