import React,{useContext,useState,useEffect,Fragment} from 'react';
// import {Link,useNavigate}  from "react-router-dom";
import {Modal} from 'react-bootstrap';
import { Row, Col, Card, Button, Dropdown, ButtonGroup, Nav } from "react-bootstrap";
import {Link,useNavigate} from 'react-router-dom';
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import Tnc from "../../../jsx/pages/Tnc";
import PropTypes from "prop-types";
import ReservationStats from './Dashboard/ReservationStats';
import LatestReview from './Dashboard/LatestReview';
import RecentBooking from './Dashboard/RecentBooking';
// import Alert from '../AppsMenu/AppProfile/Alert'
import Toastr from '../PluginsMenu/Toastr/Toastr';
import {isProfileStatus} from '../../../store/selectors/AuthSelectors';
 // isProfileStatus,
 import {

	getCurrentProfile,
	
  } from "../../../store/actions/profileAction";
const Home = ({
	name,
	email,
  profile,
	getCurrentProfile,
  asg

  
  }) => {
     const navigate = useNavigate();
    // let redirect = false
    // if(!isProfileStatus) navigate("/welcome")
    // if(profile?.profileStatus !=='MEMBER'){ redirect=true  }
    // if(redirect) navigate("/dashboard")
 	const [addCard, setAddCard] = useState(false);
	const [addCard2, setAddCard2] = useState(false);
	const [addCard3, setAddCard3] = useState(false);
	const [addCard4, setAddCard4] = useState(false);
  const [regStatusValied, setRegStatusValid] = useState({});
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);


  useEffect(() => {
    getCurrentProfile();
  }, []);


  const recheck = async()=>{

      const phone_ = profile?.phone_detail.find((element) => {
        return element.regStatus === true;
        })

        setRegStatusValid(phone_)

      if(!phone_)

        swal("Please validate at least one added phone to activate these links to use them..\n\n Thank you.", {
          icon: "warning",   });

        else if(!profile.tnc_download){
          setAddCard4(true)
         window.load()
        }	else  setAddCard3(true)

	}

  const redirect_download = async()=>{

		const phone_ = profile?.phone_detail.find((element) => {
		  return element.regStatus === true;
		  })
  
		if(!phone_){
		  swal("Please validate at least one added phone to unlock this download link.\n\n Thank you.", {
			icon: "warning",   });
			navigate("/phone-validate")
		}else navigate("/download-page")
  

  
		 // else	setAddCard3(true)
  
	  }


	return(
		<> 
		 {/* <Toastr alert={true}/> */}
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="row">
								{/* <div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
													  <path  data-name="Path 1957" d="M129.035,178.842v2.8a5.6,5.6,0,0,0,5.6,5.6h14a5.6,5.6,0,0,0,5.6-5.6v-16.8a5.6,5.6,0,0,0-5.6-5.6h-14a5.6,5.6,0,0,0-5.6,5.6v2.8a1.4,1.4,0,0,0,2.8,0v-2.8a2.8,2.8,0,0,1,2.8-2.8h14a2.8,2.8,0,0,1,2.8,2.8v16.8a2.8,2.8,0,0,1-2.8,2.8h-14a2.8,2.8,0,0,1-2.8-2.8v-2.8a1.4,1.4,0,0,0-2.8,0Zm10.62-7-1.81-1.809a1.4,1.4,0,1,1,1.98-1.981l4.2,4.2a1.4,1.4,0,0,1,0,1.981l-4.2,4.2a1.4,1.4,0,1,1-1.98-1.981l1.81-1.81h-12.02a1.4,1.4,0,1,1,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
													</svg>
												</span>
												<div className="ms-4">
													<h2 className="mb-0 font-w600">Status</h2>
													<p className="mb-0 text-nowrap">{ (profileStatus==="M-RIGHT REGISTRAR")?profileStatus:"Approved MEMBER"}</p>
												</div>
											</div>
										</div>
									</div>
								</div> */}
                <div className="card-body">
									<div className="text-center mb-3">
									<br /><div className="card-title"><strong>Welcome! </strong> {name}
                  <p className="mb-0 text-nowrap "> 
                       
                       { ( profile?.profileStatus==='M-RIGHT REGISTRAR' ) && ( <Button className="me-2" variant="warning" onClick={()=> setAddCard(true)}>
               
                       VIEW DETAIL{profile.profileStatus}
                       {/* </Link> */}
                      
                       </Button>)}   
                     
                       {( profile?.profileStatus==='M-RIGHT GSM UNION EXCO' || profile?.profileStatus==='ADMIN') && ( <Button className="me-2" variant="warning" onClick={()=> setAddCard2(true)}>
                 {/* <Link to={`/registrar/`} className="btn btn-primary "> */}
                       {/* //REGISTER AS A UNION EXCO */}
                       VIEW DETAIL
                       {/* </Link> */}
                       
                       </Button>)}   
                       {(profile && profile.profileStatus==='MEMBER' ) && ( <Button className="me-2" variant="warning" >
                        <Link to={`/registrar/`} className="btn btn-warning ">
                            <strong>Apply as phone shop agent</strong> 
                             {/* VIEW YOUR AGENT DETAIL */}
                       </Link>
                       
                       </Button>)}   
                      
           

  
   </p>
                  
                  </div>								{/* {creatingProfile ? "Create Your Profile" : "Edit Your Profile"} */}
								
                   		 </div>
                   		 </div>
    	<Modal className="modal fade"  show={addCard} onHide={setAddCard} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">AGENT DETAIL</h4>
									<button type="button" className="btn-close" onClick={()=> setAddCard(false)} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
											<div className="form-group mb-3">
												<label className="text-black font-w500">User Name: &nbsp;&nbsp;{name}</label>
												{/* <div className="contact-name">
												<select name="phoneStatus" value={formData.phoneStatus} className="form-control" onChange={onChange}  >
													<option value="">* Select Phone Status</option>
													<option value="Missing or Stolen">Missing or Stolen</option>
													<option value="Forsale">Forsale</option>
												</select>
													<span className="validation-text"></span>
												</div> */}
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">User Status: &nbsp;&nbsp;{profile.appStatus?profile.revoke?'Agentship Revoked':'Approved':'Pending'}</label>
											
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Location: &nbsp;&nbsp;{profile.appStatus?profile.revoke?'Agentship Revoked':'Approved':'Pending'}</label>
											
											</div>
										
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">Your Referral Code: &nbsp;&nbsp;<b>{((profile.phone_detail).length )? profile.my_referral_code:'< Add at least one phone to access referral code >'}</b></label> */}
											
											</div>
										
											
										</div>
									</div>
								</div>
								{/* <div className="modal-footer">
									<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Change Status</button>   
									<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div> */}
							</form>
							
						</div>
					</div>
				</Modal>
        <Modal className="modal fade"  show={addCard2} onHide={setAddCard2} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">EXCO PERFORMANCE STATUS</h4>
									<button type="button" className="btn-close" onClick={()=> setAddCard2(false)} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Exco's Name: &nbsp;&nbsp;{name} ({profile.profileStatus})</label>
												{/* <div className="contact-name">
												<select name="phoneStatus" value={formData.phoneStatus} className="form-control" onChange={onChange}  >
													<option value="">* Select Phone Status</option>
													<option value="Missing or Stolen">Missing or Stolen</option>
													<option value="Forsale">Forsale</option>
												</select>
													<span className="validation-text"></span>
												</div> */}
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Exco Status: &nbsp;&nbsp;{( profile.profileStatus==='M-RIGHT GSM UNION EXCO' || profile.profileStatus==='ADMIN')? 'APPROVED': 'PENDING'}</label>
												{/* <div className="contact-name">
												<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/>
													
													<span className="validation-text"></span>
												</div> */}
											</div>
										
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">State-wide Total Transactions : &nbsp;&nbsp;{(profile && profile?.appStatus) && profile.total}</label> */}
												{/* <div className="contact-name">
												<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/> 
													
													<span className="validation-text"></span>
												</div> */}
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">{profile.profileStatus==='M-RIGHT GSM UNION EXCO'?'State': 'National'} Phone Validation Count: &nbsp;&nbsp;{profile && profile.total}</label><br />
												<label className="text-black font-w500">Period: Current Month</label>
												{/* <div className="contact-name">
												<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/> 
													
													<span className="validation-text"></span>
												</div> */}
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Location: &nbsp;&nbsp;{profile && profile.state},&nbsp;{profile && profile.country}</label>
												{/* <div className="contact-name">
												<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/> 
													
													<span className="validation-text"></span>
												</div> */}
											</div>
											
										</div>
									</div>
								</div>
								{/* <div className="modal-footer">
									<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Change Status</button>   
									<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div> */}
							</form>
							
						</div>
					</div>
				</Modal>
        <Modal className="modal fade"  show={addCard3} onHide={setAddCard3} size='lg'>
					<div className="" role="document">

						<div className="">
						
            {  
            
            (!profile.tnc_download && regStatusValied)? 
              <Tnc /> :
              
              <Fragment>
                	<div className="modal-header">
									<h4 className="modal-title fs-20">TRACK PHONE</h4>
									<button type="button" className="btn-close" onClick={()=> setAddCard3(false)} data-dismiss="modal"><span></span></button>
								</div>
                	<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
                  <p className="text-justify">We assumed that you follow all the precautionary guide lines such as passwording Sim, configurations settings and the installations in the download page. If so, there is brighter chance to track and get your stolen phone, whether it is;
a)	Connected to internet
b)	Not connected to internet
c)	Switched off 
d)	Reset or formatted 
As Tracking phone using Emei number which often costly, time taking and not often yielding the required result due to so many factors, we only consider the 3 basic ways here;
<br /><br />
1.	    <Button
                as="a"
                variant=" "
                href="https://www.google.com/android/find/"
                className="btn-card bg-white text-secondary text-wrap"
              >https://myaccount.google.com/find-your-phone?pli=1</Button>
<br />
Above link track misplaced or missing phone connected to internet. If it’s switched off and sure that it was connected to internet before it was off, there is still chance of knowing it's location. Just remember your configuration settings or seek assistance of M-right phone shop agent that handled the installations for you.
<br /><br />
2.	If you have downloaded and installed ‘ The SMS Location App’ as guided in our download page, it’s just a matter of remembering your keywords and code to track your lost/missing phone that is <strong>NOT</strong> connected to internet. If it’s  switched off, remember your configurations setting or seek assistance of phone shop agent.

If the above 2 methods  persist, your sim might have been exited and possibly the phone might have been reset or formatted. It’s of less risk If your main SIM is passworded.  Switch to option 3 below,  wait for detection by M-right system on process of selling to others, which often takes time.
<br /><br />
3.	<strong>M-right’s searched missing phone  email alert;</strong>
We believe that you have  logon to your M-right account and declared your phone missing. If not, do this now. Once you have done that, if potential buyers search the phone in M-right system using its serial number, the system automatically sends you email alert that containing vital information to use amicably and get your phone back. <strong>NOTE</strong> that it is the M-right System does that for you. That is when the phone is searched and detected. 
<br /><br />
4. By this, M-right entrusted all the approaches needed to take charge of your missing phone. No need of calling or expecting Mright to do magic for you.
<br /><br />
5. M-right is doing it’s best to see stolen phones are recovered even for our reputation and customer satisfaction, as all the information are in our disposal and you might be called if recovered or deserve extra support.
</p>
											
										</div>
									</div>
								</div>
              </Fragment>
              
              }

							

							
						</div>


					</div>
				</Modal>

      
											
                 
											
								
						
								{/* <div className="col-xl-4 col-sm-12">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
													  <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
													</svg> 
												</span>
												<div className="ms-4">
													<h2 className="mb-0 font-w600">Upgrade</h2> 
			






												</div>
											</div>
										</div>
									</div>
								</div> */}

								{/* <div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
													  <path  data-name="Path 1957" d="M129.035,178.842v2.8a5.6,5.6,0,0,0,5.6,5.6h14a5.6,5.6,0,0,0,5.6-5.6v-16.8a5.6,5.6,0,0,0-5.6-5.6h-14a5.6,5.6,0,0,0-5.6,5.6v2.8a1.4,1.4,0,0,0,2.8,0v-2.8a2.8,2.8,0,0,1,2.8-2.8h14a2.8,2.8,0,0,1,2.8,2.8v16.8a2.8,2.8,0,0,1-2.8,2.8h-14a2.8,2.8,0,0,1-2.8-2.8v-2.8a1.4,1.4,0,0,0-2.8,0Zm10.62-7-1.81-1.809a1.4,1.4,0,1,1,1.98-1.981l4.2,4.2a1.4,1.4,0,0,1,0,1.981l-4.2,4.2a1.4,1.4,0,1,1-1.98-1.981l1.81-1.81h-12.02a1.4,1.4,0,1,1,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
													</svg>
												</span>
												<div className="ms-4">
													<h6 className="mb-0 font-w600">Earn Income</h6>
													<p className="mb-0"><Button variant="primary btn-xxs">learn more</Button></p>
												</div>
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg id="_009-log-out" data-name="009-log-out" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
													  <path  data-name="Path 1957" d="M151.435,178.842v2.8a5.6,5.6,0,0,1-5.6,5.6h-14a5.6,5.6,0,0,1-5.6-5.6v-16.8a5.6,5.6,0,0,1,5.6-5.6h14a5.6,5.6,0,0,1,5.6,5.6v2.8a1.4,1.4,0,0,1-2.8,0v-2.8a2.8,2.8,0,0,0-2.8-2.8h-14a2.8,2.8,0,0,0-2.8,2.8v16.8a2.8,2.8,0,0,0,2.8,2.8h14a2.8,2.8,0,0,0,2.8-2.8v-2.8a1.4,1.4,0,0,1,2.8,0Zm-10.62-7,1.81-1.809a1.4,1.4,0,1,0-1.98-1.981l-4.2,4.2a1.4,1.4,0,0,0,0,1.981l4.2,4.2a1.4,1.4,0,1,0,1.98-1.981l-1.81-1.81h12.02a1.4,1.4,0,1,0,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
													</svg>

												</span>
												<div className="ms-4">
													<h2 className="mb-0 font-w600">516</h2>
													<p className="mb-0">Referal</p>
												</div>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
						{/* <div className="col-xl-12">
							<div className="row">
								<div className="col-xl-6">
									<div className="card">
										<div className="card-header border-0 pb-0">
											<h4 className="fs-20">Recent Booking Schedule</h4>
										</div>
										<RecentBooking />
									</div>
								</div>
								<div className="col-xl-6">
											<div className="card">
												<div className="card-body">
													<div className="row">
														<div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
															<div className="text-center">
																<h3 className="fs-28 font-w600">569</h3>
																<span className="fs-16">Total Members</span>
															</div>
														</div>
														<div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
															<div className="text-center">
																<h3 className="fs-28 font-w600">2,342</h3>
																<span className="fs-16">Total Agents</span>
															</div>
														</div>
														<div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
															<div className="text-center">
																<h3 className="fs-28 font-w600">992</h3>
																<span className="fs-16">States Covered</span>
															</div>
														</div>
														<div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
															<div className="text-center">
																<h3 className="fs-28 font-w600">76k</h3>
																<span className="fs-16 wspace-no">Total Transaction</span>
															</div>
														</div>
														<div className="mb-5 mt-4 d-flex align-items-center">
															<div>
																<h4><Link to={"#"} className="text-secondary">Latest Phone Related Security Reports</Link></h4>
																<span className="fs-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labo
																</span>
															</div>
															<div><Link to={"#"} className="ms-5"><i className="fas fa-arrow-right fs-20"></i></Link></div>
														</div>
													</div>
												</div>
											</div>
										</div>
								<div className="col-xl-6">
									<div className="row">
										<div className="col-xl-12">
											<ReservationStats />
											
										</div>
										<div className="col-xl-6 col-sm-6">
											<div className="card bg-secondary">
												<div className="card-body">
													<div className="d-flex align-items-end pb-4 justify-content-between">
														<span className="fs-14 font-w500 text-white">Available Room Today</span>
														<span className="fs-20 font-w600 text-white"><span className="pe-2"></span>683</span>
													</div>
													<div className="progress default-progress h-auto">
														<div className="progress-bar bg-white progress-animated" style={{width: "60%", height:"13px" }}>
															<span className="sr-only">60% Complete</span>
														</div>
													</div>
													
												</div>
											</div>
										</div>
										 <div className="col-xl-6 col-sm-6">
											<div className="card bg-secondary">
												<div className="card-body">
													<div className="d-flex align-items-end pb-4 justify-content-between">
														<span className="fs-14 font-w500 text-white">Sold Out Room Today</span>
														<span className="fs-20 font-w600 text-white"><span className="pe-2"></span>156</span>
													</div>
													<div className="progress default-progress h-auto">
														<div className="progress-bar bg-white progress-animated" style={{width: "30%", height:"13px"}} >
															<span className="sr-only">30% Complete</span>
														</div>
													</div>
													
												</div>
											</div>
										</div>
										
									</div>
								</div>
								
							</div>	
							
						</div> */}
						{/* <div className="col-xl-12">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h4 className="fs-20">Why M-Right</h4>
								</div>
								<div className="card-body pt-0">
									<LatestReview  />
								</div>	
							</div>	
						</div> */}

<Fragment>
      {/* <PageTitle activeMenu="Services" pageContent="Card" motherMenu="Dashboard" /> */}
      <Row>
        {/* <Col xl="6">
          <Card>
            <Card.Header className=" border-0 pb-0">
              <Card.Title>Why M-Right?</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                He lay on his armour-like back, and if he lifted his head a
                little he could see his brown belly, slightly domed and divided
                by arches into stiff <br /> sections. The bedding was hardly
                able to cover it and seemed ready to slide off any moment.
              </Card.Text>
            </Card.Body>
            <Card.Footer className=" border-0 pt-0">
              <Card.Text className=" d-inline">Secure your Digtal Asset</Card.Text>
              <Card.Link href="#" className="float-end">
                LEARN MORE
              </Card.Link>
            </Card.Footer>
          </Card>
        </Col> */}
        {/* <Col xl="6">
          <Card>
            <Card.Header>
              <Card.Title>Become An Agent</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                This is a wider card with supporting text and below as a natural
                lead-in to the additional content. This content is a little
                <br /> bit longer. Some quick example text to build the bulk{" "}
              </Card.Text>
            </Card.Body>
            <Card.Footer className=" d-sm-flex justify-content-between align-items-center">
              <Card.Text className=" text-dark d-inline">
                Last updated 3 mins ago
              </Card.Text>

              <Link to={"#"} className="btn btn-primary">
                Go Home Page
              </Link>
            </Card.Footer>
          </Card>
        </Col> */}
        {/* <Col xl="6">
          <Card className="text-center">
            <Card.Header>
              <Card.Title>Card Title</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                This is a wider card with supporting text and below as a natural
                lead-in to the additional content. This content
              </Card.Text>
              <Button as="a" variant="primary" href="#" className="mt-3">
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer>
              <Card.Text className=" text-dark">
                Last updateed 3 min ago
              </Card.Text>
            </Card.Footer>
          </Card>
        </Col> */}
        { (asg !=='DSF@NR' ) &&  asg !=='TAPMA@NR' && (    <Col xl="12">
          <Card className="text-center">
            <Card.Header>
              <Card.Title>MRight Special Services
              
              </Card.Title>
            </Card.Header>
            <Card.Body className=" custom-tab-1 text-secondary">
              <ul className="nav nav-tabs card-body-tabs mb-3 text-warning ">
               <Nav.Item as="li">
                
                  <Nav.Link  as="button" >
                    
                  <div className="text-blue font-bold " onClick={()=> recheck(true)}>
                                   Track lost phone
                                </div>
                  
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item >
              
                  <Nav.Link  as="button" >
                      <div  className=" text-blue font-bold" onClick={()=> redirect_download(true)}>
                      Download Antitheft, other app
                                </div>
                  </Nav.Link>
                </Nav.Item>  
               
                <Nav.Item >
                  <Nav.Link  as="button">
                  <div  className=" text-blue font-bold" onClick={()=> navigate("/welcome")}>
                  Go back to main page 
                                </div>
                 
                  </Nav.Link>
                </Nav.Item>
              </ul>
 <Card.Text >{  
            
            (!profile.tnc_download && addCard4 ) && 
              <Tnc  />
              
              }</Card.Text>
              {/*
                Special services above coming soon.... 
              </Card.Text> */}
              {/* <Button variant="primary" href="#" className="mt-3">
                Go somewhere
              </Button> */}

            </Card.Body>
          </Card>
        </Col>)}
    

        {/* <Col xl="6">
          <Card className="text-white bg-primary">
            <Card.Header>
              <Card.Title className="text-white">Primary card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant=" "
                href="#"
                className="btn-card bg-white text-primary mt-3"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="text-white bg-secondary">
            <Card.Header>
              <Card.Title className="text-white">
                Secondary card title
              </Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant=" "
                href="#"
                className="btn-card bg-white text-secondary mt-3"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="text-white bg-success">
            <Card.Header>
              <Card.Title className="text-white">Success card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant=" "
                href="#"
                className="btn-card bg-white text-success mt-3"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="text-white bg-danger">
            <Card.Header>
              <Card.Title className="text-white">Danger card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant="danger light"
                href="#"
                className="btn-card bg-white text-danger mt-3"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="text-white bg-warning">
            <Card.Header>
              <Card.Title className="text-white">Warning card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant="warning light"
                href="#"
                className="btn-card bg-white tet-warning mt-3"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="text-white bg-info">
            <Card.Header>
              <Card.Title className="text-white">Info card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant="info light"
                href="#"
                className="btn-card bg-white text-info mt-3"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="bg-light">
            <Card.Header>
              <Card.Title>Light card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button as="a" variant="dark" href="#" className="btn-card mt-3">
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="text-white bg-dark">
            <Card.Header>
              <Card.Title className="text-white">Dark card title</Card.Title>
            </Card.Header>
            <Card.Body className=" mb-0">
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                as="a"
                variant="light"
                href="#"
                className="btn-card mt-3 text-dark"
              >
                Go somewhere
              </Button>
            </Card.Body>
            <Card.Footer className=" bg-transparent border-0 text-white">
              Last updateed 3 min ago
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="">
            <img
              className="card-img-top img-fluid"
              src={''}
              alt="Card cap"
            />
            <Card.Header>
              <Card.Title>Card title</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
              <Card.Text className="mt-3 text-dark">
                Last updated 3 mins ago
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="">
            <img
              className="card-img-top img-fluid"
              src={''}
              alt="Card cap"
            />
            <Card.Header>
              <Card.Title>Card title</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                He lay on his armour-like back, and if he lifted his head a
                little
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Text className=" d-inline">Card footer</Card.Text>
              <Link to="/ui-card" className="card-link float-end">
                Card link
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col xl="6">
          <Card className="">
            <Card.Header>
              <Card.Title>Card title</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                This is a wider card with supporting text and below as a natural
                lead-in to the additional content. This content is a little
              </Card.Text>
            </Card.Body>
            <img
              className="card-img-bottom img-fluid"
              src={''}
              alt="Card cap"
            />
            <Card.Footer>
              <Card.Text className=" d-inline">Card footer</Card.Text>
              <Link to="/ui-card" className="card-link float-end">
                Card link
              </Link>
            </Card.Footer>
          </Card>
        </Col> */}
      </Row>
    </Fragment>
					</div>
				</div>
			</div>		
		</>
	)
}
Home.propTypes = {

	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,

  };
  
  const mapStateToProps = (state) => ({
 //   profileStatus: state.auth.auth.profileStatus,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
  isProfileStatus: isProfileStatus(state),
  profile: state.profileReducer.profile,
  asg: state.auth.auth.asg
  });
  const mapDispatchToProps = { getCurrentProfile};
  export default connect(mapStateToProps, mapDispatchToProps)(Home);