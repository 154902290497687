// these are the constants that will use them as a flag for data manipulations.
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILES2 = "GET_PROFILES2";
export const GET_PROFILES3 = "GET_PROFILES3";
export const GET_PROFILES4 = "GET_PROFILES4";
export const GET_REPOS = "GET_REPOS";
export const NO_REPOS = "NO_REPOS";
export const CHANGE_TNC = "CHANGE_TNC";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_CREDIT = "ADD_CREDIT";
export const UPDATE_PROFILE_VALIDATE = "UPDATE_PROFILE_VALIDATE";
export const TRANSFER_CREDIT = "TRANSFER_CREDIT";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const USER_ERROR = "USER_ERROR";
export const STATE_LGA = "STATE_LGA";
export const STATELGA_ERROR = "STATELGA_ERROR";
export const SERVICE_FEE = "SERVICE_FEE";
export const SERVICE_FEE_ERROR = "SERVICE_FEE_ERROR";
export const SET_PROFILE_STATUS = '[profile action] profile status action';
export const CHANGE_STATUS = '[profile action] profile change status action';
export const CHANGE_SEARCH = '[profile action] profile search action';
