import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate, useMatch}  from "react-router-dom";
import { usePaystackPayment, PaystackButton, PaystackConsumer } from '../../dist/index.es';
import { TimeAgo, FormatDate, TimeDiff, IsExpired } from './TimeAgo'
import {Modal} from 'react-bootstrap';
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';

import { connect } from 'react-redux';

  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';
  import {
	deletePhoneAction_missing,
	deletePhoneAction_ChangeStatusValidated,
	getCurrentProfile,
	
  } from "../../store/actions/profileAction";


 
const initialState ={
	
	phoneStatus: 'IN USE',
	phoneName: '',
	password: '',
	phoneid: '',
	prof_id:null,
	status:false
}
const StolenPhone = ({
	
	deletePhoneAction_missing,
	deletePhoneAction_ChangeStatusValidated,
	profile: { profile, balance, loading },
	
	getCurrentProfile,
	name,
	email,
	phone
  
  }) =>{
	
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const [id, setId] = useState('');
	const [amount, setAmount] = useState(1000);
	const [data, setData] = useState('');
	const [isTitle, setIsTitle] = useState(false);
	const [displayInUse, setDisplayInUse] = useState(false);
	const [payFlag,setPayFlag] = useState(true)
	const [validationFlag,setValidationFlag] = useState(false)
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
	const [ref, setRef] = useState('');
	const [addCard2, setAddCard2] = useState(false);
	const phoneMissing = useMatch('/phone-missing');

	// delete data  
    const handleDelete = () => {
		let msg = 'Note that deliberate declaring of your phone missing causes authorities to track down to you and there is penalty for this.'
		let msg2 = 'Once declared, the details of the declared phone changes to IN USE'
		let message = ''
		displayInUse?message=msg2:message=msg 
        swal({
			title: "Are you sure?",
			text:
			  `${message}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
				if(phoneStatus === ''){
					swal(`Enter Phone status!`)
					return;
				  }
				  let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString(),  trans_pay_method: 'paystack', trans_amount: 3500, trans_name: 'Declare phone missing ' }
				deletePhoneAction_missing(formData2, navigate).then((res) => {
				if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
					swal(`${res}`);
				}else {
						swal(`Phone status has change to ${formData.phoneStatus}!`, {
					icon: "success",
					});
					//setAddCard(false);
					navigate("/my-phones")
					
				}
				
			});
			} else {
			  swal(`Phone is not declared ${message}!`);
			}
		  })

    }
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed')
	}

	
	const onSuccess = (reference) => {
	
		//setRef(reference)
		let trans_amount = !addCard2?3500*100:profile?.appStatus? 1550*100:1950*100
		//let trans_amount = !addCard2?3500*100:profile?.profileStatus==='M-RIGHT REGISTRAR'? 1100*100:1500*100
		let trans_name = !addCard2?'Declare phone missing':'Phone Validation'
		


		let formData2 = {...formData, trans_ref: reference,  trans_pay_method: 'paystack', trans_amount: trans_amount, trans_name: trans_name }

		!addCard2 && deletePhoneAction_missing(formData2, navigate).then((res) => {
			if(res !=='Invalid Credentials'){
					swal(`Phone status has changed to ${formData.phoneStatus}!`, {
				icon: "success",
				});
				//setAddCard(false);
				navigate("/my-phones")
			}else{
				swal("Invalid Credentials!");
			}
			
		});

		addCard2 && deletePhoneAction_ChangeStatusValidated(formData2, navigate).then((res) => {
			if(res !=='Invalid Credentials'){
					swal(`Phone status has changed to Validated!`, {
				icon: "success",
				});
				setAddCard2(false);
				navigate("/my-phones")
			}else{
				swal("Invalid Credentials!");
			}
			
		});
		
		
	};

	const handleWallet = () => {

        swal({
			title: "Are you sure?",
			text:
			  `Once submitted, phone status will change to ${formData.phoneStatus}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
				let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString(),  trans_pay_method: 'wallet', trans_amount: 3500, trans_name: 'Declare phone missing ' }

				deletePhoneAction_missing(formData2, navigate).then((res) => {
				if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
					swal(`${res}`);
				}else {
						swal(`Phone status has change to ${formData.phoneStatus}!`, {
					icon: "success",
					});
					//setAddCard(false);
					navigate("/my-phones")
					
				}
				
			});
			} else {
			  swal("Phone status is not change");
			}
		  })

    }

	const handleWallet_validate = () => {

        swal({
			title: "Are you sure?",
			text:
			  `Once submitted, phone reg. status will change to Validated`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {

				let trans_amount = profile?.appStatus? 1550:1950
				let formData2 = {...formData, trans_ref:  (new Date()).getTime().toString() ,  trans_pay_method: 'wallet', trans_amount: trans_amount, trans_name: 'Phone Validation' }

				deletePhoneAction_ChangeStatusValidated(formData2, navigate).then((res) => {
				
				if(res ==='Insufficient balance' || res ==='Invalid Credentials' || res ==='Transaction Error'){
					swal(`${res}`);
				}else {
						swal(`Phone status has change to Validated!`, {
					icon: "success",
					});
					setAddCard2(false);
					navigate("/my-phones")
					
				}
				
			});
			} else {
			  swal("Phone status is not change");
			}
		  })

    }
	const handleValidateFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneid === '') {
            errorObj.phoneid = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneid, "error");
        }


        setErrors(errorObj);
        if (error) return;

		handleWallet_validate()
		
		//handleDelete()
		
    };


	
	

	const componentProps = {
		//   ...config,
		reference: (new Date()).getTime().toString(),
		email:email,
		amount:!addCard2?3500*100:profile?.appStatus? 1550*100:1950*100,
		metadata: {
			name,
			 phone,
		  },
		publicKey: publicKey,
		
	   //  lastname: 'story',
		   text: 'Proceed to CHECKOUT ',
		   onSuccess,
		   onClose
	   };


	const {	password, phoneid,phoneStatus, phoneName} = formData;
	let errorsObj = {
		phoneid:'',
		password:'',
		phoneStatus:'',
		phoneName:''
	   };

	useEffect(() => {
		const userData = { ...initialState };
		// if there is no profile, attempt to fetch one
		!profile && getCurrentProfile();
		id  && getPhoneStatus()
		// if(profile && !loading ) {
			
		// 	const user = profile?.phone_detail.find((element) => {
		// 		return element._id === formData.phoneid;
		// 		})
		// 		//console.log(user);
			 
		// 	  for (const key in user) {
		// 		//console.log(key);
		// 		if (key in userData) userData[key] = user[key];
		// 	  }
  		// // set local state with the userData
		//   		 setFormData(userData);
		// 		//   
			
		// }
		
	  }, [loading, profile, getCurrentProfile, id]);

	  const [errors, setErrors] = useState(errorsObj);


	  const selectedPhone =  (id)=>{
			let found=''
				
			found = profile?.phone_detail.find(function (element) {
			return element._id === id;
			});

			if(!found?.regStatus){
				setData(found);
				setAddCard2(true)
				setIsTitle(true)
				swal('Selected phone has not been validated!');
			   }
			//    else{
			// 	setData('');
			// 	setIsTitle(false)
			// 	swal('Selected phone has not been validated!');
			// 	navigate('/my-phones')
			//    }

	  }

	  const onChange = (e) =>{

			  //onComCertChanged()
				 setFormData({ ...formData, [e.target.name]: e.target.value,prof_id: profile._id ,phoneStatus:'Missing or Stolen' });
			     e.target.value === 'Missing or Stolen'  ? setValidationFlag(true): setValidationFlag(false)
	  
	 			 setId(e.target.value);
				  selectedPhone(e.target.value)
			    //let found=''
			
				// found = profile?.phone_detail.find(function (element) {
				// return element._id === e.target.value;
				//   });
			  
				// if(found && !found.regStatus){
				// setData(found);
				// setAddCard2(true)
				// }

			
				
			  
			 
	  }

    
		const getPhoneStatus = ()=>{

		let found =''
		if(profile){
				found = profile?.phone_detail?.find(function (element) {
				return element._id === id;
			});
		}
//console.log(found)
		if(found && (found.phoneStatus=='Forsale' || found.phoneStatus=='Missing or Stolen' )) {
			setDisplayInUse(true);
			setFormData({ ...formData, prof_id: profile._id, phoneStatus:'IN USE' });

		}
	

	}

	const onComCertChanged = () => 
	{
	  
	  setFormData({ ...formData, status:formData.status?false:true,phoneStatus:'IN USE'});

	}


     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneid === '') {
            errorObj.phoneid = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneid, "error");
        }
		//  if (password === '') {
        //     errorObj.password = 'Password is Required';
        //     error = true;
		// 	swal('Oops', errorObj.password, "error");
        // }
		

        setErrors(errorObj);
        if (error) return;
		selectedPhone(phoneid)
		phoneStatus === 'Missing or Stolen'? setValidationFlag(true): handleDelete()
		//handleDelete()
    };
	
	const handleAddFormSubmit2 = ()=> {
     
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneStatus === '') {
            errorObj.phoneStatus = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneStatus, "error");
        }
		//  if (password === '') {
        //     errorObj.password = 'Password is Required';
        //     error = true;
		// 	swal('Oops', errorObj.password, "error");
        // }
		

        setErrors(errorObj);
        if (error) return;

		 handleWallet()
		
		//handleDelete()
		
    };
	
	const handlePayFlagClick = () => {
		setPayFlag(!payFlag);    
		
   };

	const handlePayFlagClick2 = () => {
		setPayFlag(!payFlag);    
		
   };

	const payButton = ()=>	{
	  
		let onlinePay = ''
		if(!payFlag){
			   onlinePay = <PaystackButton className="btn btn-secondary my-1" {...componentProps} />
		}else{
			  onlinePay = <button type="submit" className="btn btn-secondary my-1" onClick={handleAddFormSubmit2}>PAY via Phone Shop Validation</button>   
		}
	  
		  return (<Fragment>
					  
						  <div className="col-xl-12 col-lg-8 ml-14">
							  <div className="card">
								  <div className="card-header ">
								  <h4 className="card-title">Payment Options</h4>
								  </div>
								  <div className="card-body">
								  <div className="basic-form">
									  <form onSubmit={(e) => e.preventDefault()}>
									  <div className="form-group mb-0 " >
											  {	
									  
											  payFlag   &&  
											  (<Fragment>
												  
												  <div className="radio">
													  <label>
														  <input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Phone Shop Validation</b>
													  </label>
												  </div>
												  <div className="radio">
													  <label>
														  <input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Pay Online</b>
													  </label>
												  </div>
											  </Fragment>
											  )
	  
										  }
										  {	
									  
											  !payFlag   && 
											  (<Fragment>
												  <div className="radio">
													  <label>
														  <input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Phone Shop Validation</b>
													  </label>
												  </div>
												  <div className="radio">
													  <label>
														  <input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Pay Online</b>
													  </label>
												  </div>
											  </Fragment>
											  )
	  
										  }
  
  
										  
										  
										  
									  </div>
									  </form>
								  </div>
								  </div>
							  </div>
						  </div>
						  <div className="modal-footer">
							  {onlinePay}
							  <button type="button" onClick={()=> {  setPayFlag(true); setFormData({ ...formData, phoneStatus: ''});}  } className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
  
						  </div>
	  
		  </Fragment>)

	  
  };

  const payButton_validate = ()=>	{
	  
	let onlinePay = ''
	if(!payFlag){
	  
	  //	setFormData({ ...formData, trans_pay_method: 'paystack', trans_amount: 4500, trans_name: 'Declare phone missing '});
	  
	  
	 // checked = 'defaultChecked'
		   onlinePay = <PaystackButton className="btn btn-secondary my-1" {...componentProps} />
	}else{
	  //checked = ''
	  //	setFormData({ ...formData, trans_pay_method: 'wallet', trans_amount: 4500, trans_name: 'Declare phone missing ' });
		  onlinePay = <button type="submit" className="btn btn-secondary my-1" onClick={handleValidateFormSubmit}>PAY via Phone Shop Validation -Your Bal is &#8358;{balance}</button>   
	}
  
	  return (<Fragment>

					  <div className="col-xl-6 col-lg-8">
						 
							  <div className="card-header">
							  <h4 className="card-title">Payment Options</h4>
							  </div>
							  <div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
									<div className="form-group mb-0">
										
										
										{	
									
											payFlag   && 
											(<Fragment>
												
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Phone Shop Validation</b>
													</label>
												</div>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick2}  />  &#160;<b>Pay Online</b>
													</label>
												</div>
											</Fragment>
											)
	
										}
										{	
									
											!payFlag   && 
											(<Fragment>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick}  />  &#160;<b>Phone Shop Validation</b>
													</label>
												</div>
												<div className="radio">
													<label>
														<input type="radio" name="optradio" onClick={handlePayFlagClick} defaultChecked />  &#160;<b>Pay Online</b>
													</label>
												</div>
											</Fragment>
											)
	
										}


										
										
										
									</div>
									</form>
								</div>
							  </div>
						 
					  </div>
					  
					  

					  <div className="modal-footer">
						  {onlinePay}
						  <button type="button" onClick={()=> {setAddCard2(false);  setPayFlag(true);}  } className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>

					  </div>
  
	  </Fragment>)
	  // return (<Fragment>
	  // 			<div className="modal-footer">
	  // 			<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
	  // 					<button type="button" onClick={()=> {setAddCard(false); setFormData({ ...formData, phoneStatus: ''});}} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
	  // 			</div>
  
	  // </Fragment>)
  
	  };
	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
			<div className="col-12">
				
			<Modal className="modal fade"  show={addCard2} onHide={setAddCard2} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Phone Validation</h4>
									<button type="button" className="btn-close" onClick={()=> {setAddCard2(false);setFormData({ ...formData, [phoneStatus]: ''}); }} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											<div className="form-group mb-3">
												{/* <label className="text-black font-w500">Phone Status</label> */}
												<h4 className="modal-title fs-20">Phone</h4>
												<div className="contact-name">
												{data.phoneName}
												<span className="validation-text"></span>


												</div><h4 className="modal-title fs-20">Phone Color</h4>
												<div className="contact-name">
												{data.phonecolor}
												<span className="validation-text"></span>
												</div><h4 className="modal-title fs-20">Phone Reg.  Status</h4>
												<div className="contact-name">
												<IsExpired date={data.date} /><br />					
													<div className="card-header">
														<h4 className="card-title btn">Phone Validation Fee: &#8358;{`${profile?.appStatus?1550:1950}`}</h4>
													</div>
												</div>
											</div>

											
										
											
										</div>
									</div>
								</div>

								
								{/* {!validationFlag &&  (  
								<Fragment>
									<div className="modal-footer">
										<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Change Status</button>   
										<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
									</div>
								</Fragment>
						)}	 */}
							</form>
							{ payButton_validate()	}	
							
						</div>
					</div>
				</Modal>

				<div className="card">
					{/* <div className="card-header">
						<h4 className="card-title">Declare Phone Missing</h4>
					</div> */}
					<div className="card-body">
					
					<div className="" role="document">

						{/* { data?.regStatus && (<span>Click here to go to validation page</span>)} */}
					<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">{phoneMissing? data?.regStatus? 'Select Phone to Declare  Missing or Stolen':'Select Phone to Declare  Missing or Stolen':'Select Phone to Validate'} </h4>
									
								</div>

								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Phone</label>
												
												
												<div className="contact-name">
												<select name="phoneid" value={formData.phoneid} className="form-control" onChange={onChange}  >
												<option value="">* Select Phone</option>
													{ 
									
									
											   profile?.phone_detail  && profile?.phone_detail?.map(
												({
												_id,
												phoneName,
												phonesno,
												phoneStatus
													}) => (
												<option key={_id} value={_id}>{`${phoneName}- ${phoneStatus}\n Sno.:${phonesno}`}</option>
												))
										
													} 

										
          
         									 </select>
													<span className="validation-text"></span>
												</div>



											</div>
											{displayInUse && (
													<div className="form-group mb-3">
												<label className="text-black font-w500">Reverse Status to IN USE</label>
												<div className="contact-name">
													<input
														type="checkbox"
														
														className="form-check-input p-3"
														id="customCheckBox9"
														required

														name={formData.status}
														value={formData.status}
														checked ={ formData.status && 'checked' }
														// onChange={(e) =>
														//   setActive(e.target.value)
														// }
														onChange={onComCertChanged}

														/>

													<span className="validation-text"></span>
												</div>
								</div>
								)}
						


											
											{/* <div className="form-group mb-3">
												<label className="text-black font-w500">Login Password</label>
												<div className="contact-name">
													<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/>
													
													<span className="validation-text"></span>
												</div>
											</div> */}
											
											
										</div>
									</div>
								</div>
								

								{!validationFlag &&  (  
							
									<Fragment>
										<div className="modal-footer">
											<button type="submit" className="btn btn-secondary" onClick={handleAddFormSubmit}>Declare {displayInUse?'IN USE': 'Stolen'}</button>   
												{/* <button type="button" className="btn btn-danger"> <i className="flaticon-delete-1"></i></button>    */}
											<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button> 
										</div>
									</Fragment>

								)}	
						
							</form>

							{/* {validationFlag &&  (  
								<Fragment>
									<div className="modal-footer">
										<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
										<button type="button" onClick={()=> { setFormData({ ...formData, phoneStatus: ''});}} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
									</div>
								
								</Fragment>
							)}	 */}

								{validationFlag &&  payButton()	}	

						</div>



					</div>
			
					</div>
				</div>
			</div>
		</>
	)
}


StolenPhone.propTypes = {
	deletePhoneAction_missing: PropTypes.func.isRequired,
	deletePhoneAction_ChangeStatusValidated: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	
	
  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
	phone: state.auth.auth.phone,

  });
  const mapDispatchToProps = { deletePhoneAction_missing,getCurrentProfile , deletePhoneAction_ChangeStatusValidated};
  
  export default connect(mapStateToProps, mapDispatchToProps)(
	StolenPhone
  );